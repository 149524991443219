/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { Settings as SlickSettings } from 'react-slick';

import { BallType } from '../PickingBall';

import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import Ball from 'components/molecules/Ball';
import Carousel, { NextArrow, PrevArrow } from 'components/organisms/Carousel';
import Emoji, { EmojiIconTypes } from 'components/organisms/Emoji';
import PrizeCollapse, { PrizeTypes } from 'components/organisms/PrizeCollapse';
import TfcPlayer from 'components/organisms/TfcPlayer';
import { useDidMount } from 'helpers/react-hook';
import { GetMemberResponse } from 'services/member/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMeAction } from 'store/me';
import mapModifiers from 'utils/functions';

export type LiveStreamConfigs = {
  live_vn?: string;
  live_en?: string;
  start_date?: string;
  start_live?: string;
  end_live?: string;
  pusher_app_key?: string;
  theoplayer_license: string;
  theoplayer_library_location: string;
  link_livestream: string;
}

export type PrizeListTypes = {
  id: number;
  title: string;
  data: PrizeTypes[];
  isSpecial?: boolean;
}

interface LivestreamControlGeneralProps {
  storeName?: string;
  numberList?: BallType[];
  meData: GetMemberResponse;
  handleBack?: () => void;
}

interface LivestreamProps extends LivestreamControlGeneralProps {
  livestreamConfig: LiveStreamConfigs;
  prizeListData?: PrizeListTypes[];
  handleEnd?: () => void;
  handleFullscreen?: (isFull: boolean) => void;
  handleInteract?: (val: EmojiIconTypes) => void;
}

interface LivestreamControlProps extends LivestreamControlGeneralProps { }
interface LivestreamResultMobileProps {
  numberList?: BallType[];
  prizeListData?: PrizeListTypes[];
}

const LivestreamControl: React.FC<LivestreamControlProps> = ({
  storeName,
  numberList,
  meData,
  handleBack,
}) => {
  const settingDefault: SlickSettings = {
    dots: true,
    dotsClass: 'slick-dots o-carousel_dots',
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow extendClassname="sparkles small" />,
    nextArrow: <NextArrow extendClassname="sparkles small" />,
    infinite: false,
    cssEase: 'ease-in-out',
  };
  const dispatch = useAppDispatch();
  // const socketLivestream = useCallback(() => {
  //   dispatch(getMeAction());
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [meData]);
  // useDidMount(() => {
  //   const channel = echo.private(`EventTime.${meData.event_time_code}`);
  //   channel.listen('updated', socketLivestream);
  //   return () => {
  //     channel.stopListening('updated', socketLivestream);
  //   };
  // });
  return (
    <div className="t-livestream_control">
      <div className="t-livestream_control_top">
        <div className="t-livestream_control_top_left">
          <Heading type="h4" modifiers={['pigment', '700']}>
            Kính chào
            {' '}
            {storeName}
          </Heading>
          <div className="u-mt-xl-8 u-mt-4">
            <Text modifiers={['16x22', 'jet', 'fontUrmAvo']}>
              Số dự thưởng đã chọn của Quý khách:
            </Text>
          </div>
        </div>
        {/* {
          meData.redirect_to_page.includes('FOYER')
          && (
          <div className="t-livestream_control_top_right">
            <div className="t-livestream_control_button">
              <Button size="sm" handleClick={handleBack}>
                QUAY LẠI KHU TRẢI NGHIỆM
              </Button>
            </div>
          </div>
          )
        } */}
      </div>
      <div className="t-livestream_control_slider">
        <div className="t-livestream_control_slider_wrap">
          <Carousel settings={settingDefault}>
            {
              numberList && numberList.length > 0
              && numberList.map((val, idx) => (
                <div
                  className="t-numberCongrate_slider_item"
                  key={`i-${idx.toString()}`}
                >
                  <Ball value={val.value} variant={val.variant} size={103} />
                </div>
              ))
            }
          </Carousel>
        </div>
      </div>
    </div>
  );
};

type ResultLabel = {
  id: number;
  label: string;
}

const label: ResultLabel[] = [
  {
    id: 1,
    label: 'KẾT QUẢ QUAY SỐ TRÚNG THƯỞNG',
  },
  {
    id: 2,
    label: 'SỐ DỰ THƯỞNG ĐÃ CHỌN',
  },
];
const LivestreamResultMobile: React.FC<LivestreamResultMobileProps> = ({
  numberList, prizeListData,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [idxActive, setIdxActive] = useState(-1);
  const [dataLabel] = useState<ResultLabel[]>(label);

  const reorder = (id: number): ResultLabel[] => {
    const newArray = label.slice(); // clone array
    newArray.sort((a) => (a.id !== id ? -1 : 1)); // put in bottom
    return newArray;
  };

  // useEffect(() => {
  //   const result = reorder(idxActive);
  //   if (idxActive < 0) setDataLabel(label);
  //   else setDataLabel(result);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [idxActive]);

  const handleToggle = (id: number) => {
    setIdxActive(id === idxActive ? -1 : id);
  };

  return (
    <div className={`t-livestream_result${isExpanded ? ' open' : ''}${idxActive > 0 ? ' visible' : ''}`}>
      <div
        className="t-livestream_result_icon"
        onClick={() => {
          setIsExpanded(!isExpanded);
          setIdxActive(-1);
        }}
      />
      <div className="t-livestream_result_tab">
        {
          dataLabel.map((val, idx) => (
            <div
              className={`t-livestream_result_label${idxActive === val.id ? ' active' : ''}`}
              onClick={() => handleToggle(val.id)}
              key={`i-${idx.toString()}`}
            >
              <Text modifiers={['12x16', 'pigment', '500']}>
                {val.label}
              </Text>
            </div>
          ))
        }
      </div>
      <div className="t-livestream_result_content">
        {
          (() => {
            switch (idxActive) {
              case 1:
                return (
                  <div className="t-livestream_result_table">
                    {
                      prizeListData?.map((val, idx) => (
                        <div className="t-livestream_result_table_item" key={`i-${idx.toString()}`}>
                          <PrizeCollapse
                            title={val.title}
                            prizeData={val.data}
                            modifiers={[val.isSpecial ? 'active' : '', 'small']}
                          />
                        </div>
                      ))
                    }
                  </div>
                );
              case 2:
                return (
                  <div className="t-livestream_result_number">
                    {
                      numberList?.map((val, idx) => (
                        <div className="t-livestream_result_number_item" key={`i-${idx.toString()}`}>
                          <Ball value={val.value} variant={val.variant} size={45} />
                        </div>
                      ))
                    }
                  </div>
                );
              default:
                return null;
            }
          })()
        }
      </div>
    </div>
  );
};

const Livestream: React.FC<LivestreamProps> = ({
  livestreamConfig, prizeListData, storeName, numberList, meData,
  handleBack, handleEnd, handleInteract, handleFullscreen,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  return (
    <div className={mapModifiers('t-livestream', isFullscreen && 'fullscreen')}>
      {/* VIDEO  */}
      <div className="t-livestream_video" id="livestreamVideo">
        {/* RESULT PANEL MOBILE  */}
        {
          isFullscreen
          && (
            <LivestreamResultMobile
              prizeListData={prizeListData}
              numberList={numberList}
            />
          )
        }
        <div className="t-livestream_video_live">
          {livestreamConfig.link_livestream && (
            <TfcPlayer
              src={livestreamConfig.link_livestream}
              handleEnd={handleEnd}
            />
          )}
        </div>
        <div className="t-livestream_interaction">
          <Emoji
            handleClick={handleInteract}
            modifiers={isFullscreen ? 'transparent' : ''}
          />
        </div>
        <div
          className="t-livestream_fullscreen"
          onClick={() => {
            setIsFullscreen(!isFullscreen);
            if (handleFullscreen) handleFullscreen(!isFullscreen);
          }}
        >
          {isFullscreen ? (
            <Icon iconName="minimize" iconSize="24x24" />
          ) : (
            <Icon iconName="expand" iconSize="24x24" />
          )}
        </div>
      </div>
      {/* PRIZE  */}
      <div className="t-livestream_prize">
        <div className="t-livestream_prize_title">
          <Heading type="h3" modifiers={['700', 'pigment', 'center', 'md', 'center', 'uppercase']}>
            KẾT QUẢ QUAY SỐ TRÚNG THƯỞNG
          </Heading>
        </div>
        <div className="t-livestream_prize_list">
          {
            prizeListData && prizeListData.length > 0
            && prizeListData.map((val, idx) => (
              <div className="t-livestream_prize_item" key={`i-${idx.toString()}`}>
                <PrizeCollapse
                  title={val.title}
                  prizeData={val.data}
                  modifiers={[val.isSpecial ? 'active' : '']}
                />
              </div>
            ))
          }

        </div>
      </div>
      {/* CONTROL  */}
      <LivestreamControl
        numberList={numberList}
        storeName={storeName}
        handleBack={handleBack}
        meData={meData}
      />
    </div>
  );
};

Livestream.defaultProps = {
  prizeListData: undefined,
  storeName: undefined,
  handleBack: undefined,
  numberList: undefined,
  handleEnd: undefined,
  handleInteract: undefined,
  handleFullscreen: undefined,
};
LivestreamControl.defaultProps = {
  storeName: undefined,
  handleBack: undefined,
  numberList: undefined,
};
LivestreamResultMobile.defaultProps = {
  numberList: undefined,
  prizeListData: undefined,
};

export default Livestream;
