/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';

import { menuDataFoyer } from 'assets/data/foyer';
import Foyer from 'components/templates/Foyer';
import FoyerAio from 'components/templates/FoyerAio';
import { getLocalStorage, setLocalStorage } from 'services/common/storage';
import trackingOnlineService from 'services/tracking';
import { TrackingOnlineDataTypes } from 'services/tracking/type';
import { getConfigsFoyerAction } from 'store/configs';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const FoyerContainer: React.FC = () => {
  const { foyer: foyerConfigs } = useAppSelector((state) => state.configs);
  const { data: meData, loading } = useAppSelector((state) => state.me);
  const dispatch = useAppDispatch();
  /**
   *  TRACKING ONLINE
   */

  const getDateTimeTracking = (): string => {
    const today = new Date();
    return `${today.getFullYear()}-${
      today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1
    }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()} ${
      today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()
    }:${
      today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()
    }:${today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds()}`;
  };
  const sendTracking = async (data: TrackingOnlineDataTypes[]) => {
    if (!data) return;
    try {
      await trackingOnlineService({
        data_tracking: data,
      });
    } catch {
    // TODO: error
    }
  };

  useEffect(() => {
    let counter = 0;
    const trackingSaved = getLocalStorage('trackingDataFoyer');
    const trackingArr: TrackingOnlineDataTypes[] = trackingSaved
      ? JSON.parse(trackingSaved)
      : [];

    const interval = setInterval(() => {
      counter += 1;
      if (counter === 3) {
        trackingArr.push({ zone: 1, time_tracking: getDateTimeTracking() });
        sendTracking(trackingArr);
        counter = 0;
        trackingArr.splice(0, trackingArr.length);
      } else {
        trackingArr.push({ zone: 1, time_tracking: getDateTimeTracking() });
        setLocalStorage('trackingDataFoyer', JSON.stringify(trackingArr));
      }
    }, 60000);
    return () => {
      sendTracking(trackingArr);
      getLocalStorage('trackingDataFoyer');
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    dispatch(getConfigsFoyerAction());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!meData) return <>loading</>;

  return (
    <FoyerAio
      menuData={menuDataFoyer}
      foyerData={foyerConfigs}
      meData={meData}
    />
    // <Foyer
    // // permissionCam={permission !== 'granted'}
    //   menuData={menuDataFoyer}
    //   foyerData={foyerConfigs}
    // />
  );
};

export default FoyerContainer;
