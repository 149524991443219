import React from 'react';

import CheckInContainer from 'containers/CheckIn';

const CheckIn: React.FC = () => (
  <div className="p-checkIn">
    <CheckInContainer />
  </div>
);

export default CheckIn;
