/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useFormContext } from 'react-hook-form';

import { OptionType } from 'components/molecules/Pulldown';
import { getPlaceService } from 'services/checkin';
import { getEventTimeAction } from 'store/checkIn';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { formatDateEvent } from 'utils/functions';

interface Arg {
  startDateOptions: OptionType[];
  placeOptions: OptionType[];
}

interface Props {
  children: (arg: Arg) => React.ReactNode;
}

const EventCheckinProvider: React.FC<Props> = ({
  children,
}) => {
  const { watch, setValue } = useFormContext();
  const startDateCurrent = useRef<OptionType>();
  const [startDateState, setStartDateState] = useState<OptionType>();
  const [placeOptions, setPlaceOptions] = useState<OptionType[]>();
  const dispatch = useAppDispatch();
  const { eventData } = useAppSelector((state) => state.checkin);
  // =============Value selected=============
  const eventTimeValue = watch('startDate');
  useEffect(() => {
    startDateCurrent.current = eventTimeValue;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getEventTimeAction());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startDateOptions = useMemo(() => (
    eventData ? (
      eventData.map((val) => ({
        label: formatDateEvent(val.start_conference),
        value: val.code,
      }))
    ) : []), [eventData]);

  useEffect(() => {
    setStartDateState(eventTimeValue);
  }, [eventTimeValue]);

  // UPDATE OPTIONS
  // update: --- provinces options
  useEffect(() => {
    if (startDateState) {
      if (startDateCurrent.current?.value !== startDateState?.value) {
        startDateCurrent.current = startDateState;
        setValue('place', null);
      }
      (async () => {
        const res = await getPlaceService(eventTimeValue.value);
        const options = res.map((val) => ({
          label: val,
          value: val,
        }));
        setPlaceOptions(options);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateState]);

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {(children as any)({
        startDateOptions,
        placeOptions,
      })}
    </>
  );
};

export default EventCheckinProvider;
