import React from 'react';
import ReactSlick, { Settings } from 'react-slick';

import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import HeadingSection from 'components/molecules/HeadingSection';
import NumberBall from 'components/molecules/NumberBall';
import { NextArrow, PrevArrow } from 'components/organisms/Carousel';
import NumberBannerLayout from 'components/organisms/NumberBannerLayout';
import { addZeroPrefixNumberPositive } from 'utils/functions';

interface NumberCongrateProps {
  account?: string;
  time?: string;
  address?: string;
  numberPickedList?: string[];
  numberAvailable?: number;
}
interface NumberCongrateInfoProps {
  label?: string;
  value?: string | number;
}

const settings: Settings = {
  dots: false,
  arrows: true,
  slidesToShow: 6,
  slidesToScroll: 6,
  prevArrow: <PrevArrow extendClassname="sparkles" />,
  nextArrow: <NextArrow extendClassname="sparkles" />,
  infinite: false,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
  ],
};
const NumberCongrateInfo: React.FC<NumberCongrateInfoProps> = ({
  label, value,
}) => (
  <div className="t-numberCongrate_info_item">
    <Text modifiers={['400', 'jet', 'fontUrmAvo', '16x22']}>
      {label}
      {' '}
      <Text type="span" modifiers={['700', 'pigment', 'fontUrmAvo', '16x22']}>
        {value}
      </Text>
    </Text>
  </div>
);

const NumberCongrate: React.FC<NumberCongrateProps> = ({
  account,
  time,
  // eslint-disable-next-line no-unused-vars
  address, numberPickedList, numberAvailable,
}) => (
  <div className="t-numberCongrate">
    <NumberBannerLayout>
      <div className="t-numberCongrate_panel">
        <div className="t-numberCongrate_panel_wrap">
          <div className="t-numberCongrate_heading">
            <HeadingSection modifiers="secondary">
              CHÚC MỪNG QUÝ KHÁCH ĐÃ CHỌN SỐ THÀNH CÔNG
            </HeadingSection>
          </div>
          <div className="t-numberCongrate_content">
            <Text modifiers={['700', 'jet', 'fontUrmAvo', '16x22']}>
              Thông tin tham dự chương trình quay số trực tuyến
            </Text>
            <div className="t-numberCongrate_info">
              {/* Account  */}
              <NumberCongrateInfo
                label="Tài khoản đăng nhập:"
                value={account}
              />
              {/* Time  */}
              <NumberCongrateInfo
                label="Thời gian bắt đầu buổi phát sóng trực tiếp chương trình quay số:"
                value={time}
              />
              {/* Number  */}
              <NumberCongrateInfo
                label="Tổng số lượng số dự thưởng:"
                value={addZeroPrefixNumberPositive(numberAvailable)}
              />
            </div>
            <div className="t-numberCongrate_info_item">
              <Text modifiers={['400', 'jet', 'fontUrmAvo', '16x22']}>
                Số dự thưởng đã chọn:
              </Text>
              <div className="t-numberCongrate_info_listnumber">
                {
                  numberPickedList
                  && numberPickedList.length > 0
                  && (
                    <ReactSlick {...settings}>
                      {
                        numberPickedList.map((val, idx) => (
                          <div
                            className="t-numberCongrate_list_item"
                            key={`i-${idx.toString()}`}
                          >
                            <NumberBall
                              value={val}
                              type="picking"
                              disabled
                              size="sm"
                            />
                          </div>
                        ))
                      }
                    </ReactSlick>
                  )
                }
              </div>
            </div>
            <div className="u-mt-24">
              <Text modifiers={['400', 'jet', 'fontUrmAvo', '16x22']}>
                Mọi thắc mắc xin vui lòng liên hệ tổng đài:
                {' '}
                <Link href="tel:0918454049" useExternal>
                  <Text type="span" modifiers={['700', 'jet', 'fontUrmAvo', '16x22']}>
                    0918 45 40 49
                  </Text>
                </Link>
              </Text>
            </div>
            <div className="u-mt-md-32 u-mt-18">
              <Text modifiers={['fontUrmAvo', '20x24', 'pigment', 'center', 'uppercase', '700', 'italic']}>
                chúc quý khách may mắn
              </Text>
            </div>
          </div>
        </div>
      </div>
    </NumberBannerLayout>
  </div>
);

NumberCongrate.defaultProps = {
  account: undefined,
  time: undefined,
  address: undefined,
  numberPickedList: undefined,
  numberAvailable: undefined,
};
NumberCongrateInfo.defaultProps = {
  label: undefined,
  value: undefined,
};

export default NumberCongrate;
