/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import instructionDataImage from 'assets/data';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Container from 'components/organisms/Container';
import useWindowDimensions from 'hooks/useWindowDimension';
import SLUG_PARAMS from 'utils/constants';
import mapModifiers from 'utils/functions';

interface InstructionProps {
}

const Instruction: React.FC<InstructionProps> = () => {
  const [idxActive, setIdxActive] = useState(0);
  const history = useHistory();
  const [resize, setResize] = useState<number>();
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width && width <= 1024) {
      setResize(window.innerHeight);
    } else setResize(undefined);
  }, [width]);
  useEffect(() => {
    document.body.classList.add('body-instruction');
  }, []);
  return (
    <div
      className={mapModifiers('t-instruction')}
      style={{
        height: resize,
      }}
    >
      <Container fullScreen>
        <div className="t-instruction_background">
          <Image
            modifiers="normal"
            imgSrc={instructionDataImage[idxActive].pc}
            srcMobile={instructionDataImage[idxActive].mobile}
            srcTablet={instructionDataImage[idxActive].mobile}
            ratio="1368x813"
            size="cover"
            alt="step"
          />
        </div>
      </Container>
      <div className="t-instruction_button">
        <Button
          color="pigment"
          variant={['h50']}
          handleClick={() => {
            if (idxActive < instructionDataImage.length - 1) {
              setIdxActive(idxActive + 1);
            } else {
              history.push(SLUG_PARAMS.NUMBER_PICKING);
            }
          }}
        >
          {
            idxActive === instructionDataImage.length - 1 ? 'BẮT ĐẦU CHỌN SỐ' : 'TIẾP TỤC HƯỚNG DẪN'
          }
        </Button>
      </div>
    </div>
  );
};

Instruction.defaultProps = {
};

export default Instruction;
