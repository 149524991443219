import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EmojiIconTypes } from 'components/organisms/Emoji';
import getGeneralService from 'services/general';
import { GeneralTypes } from 'services/general/type';
import { GetMemberResponse } from 'services/member/types';
import { LuckyDrawsSocketTypes } from 'services/picking/type';

export type NumberSelectionData = {
  eventTimeCode: string
  number: number
  select: boolean
  startTime: number
  uid: number
}

interface GeneralState {
  data?: GeneralTypes;
  loading?: boolean;
  socketStatus?: number;
  luckyDrawsSocket: LuckyDrawsSocketTypes[];
  emojiSocket: EmojiIconTypes[];
  numberSocket: NumberSelectionData[];
  resetNumberSocket?: {numbers: number[]};
}

const initialState: GeneralState = {
  data: undefined,
  loading: false,
  luckyDrawsSocket: [],
  emojiSocket: [],
  numberSocket: [],
};

export const getGeneralAction = createAsyncThunk<
GeneralTypes, void, {rejectValue: ErrorResponse}
>(
  'general/getGeneralAction', async (_, { rejectWithValue }) => {
    try {
      const res = await getGeneralService();
      return res;
    } catch (error) {
      return rejectWithValue(error as ErrorResponse);
    }
  },
);

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    addResetNumberSocket: ($state, action: PayloadAction<{numbers: number[]}>) => {
      $state.resetNumberSocket = action.payload;
    },
    addNewNumberSocket: ($state, action: PayloadAction<{number: NumberSelectionData,
      userData: GetMemberResponse}>) => {
      const { number, userData } = action.payload;
      const clone = $state.numberSocket.slice();
      if (number.uid !== userData.id && number.eventTimeCode === userData.event_time_code) {
        $state.numberSocket = [number, ...clone];
      }
    },
    addNewEmojiSocket: ($state, action: PayloadAction<EmojiIconTypes>) => {
      const clone = $state.emojiSocket.slice();
      $state.emojiSocket = [action.payload, ...clone];
    },
    addNewLuckyDrawsSocket: ($state, action: PayloadAction<LuckyDrawsSocketTypes>) => {
      const clone = $state.luckyDrawsSocket.slice();
      $state.luckyDrawsSocket = [action.payload, ...clone];
    },
    setSocketStatus: ($state, action: PayloadAction<number>) => {
      $state.socketStatus = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getGeneralAction.pending, ($state) => {
      $state.loading = true;
    });
    builder.addCase(getGeneralAction.fulfilled, ($state, action) => {
      $state.data = action.payload;
    });
    builder.addCase(getGeneralAction.rejected, ($state) => {
      $state.loading = false;
    });
  },
});
export const {
  addResetNumberSocket,
  addNewNumberSocket,
  addNewEmojiSocket,
  addNewLuckyDrawsSocket,
  setSocketStatus,
} = generalSlice.actions;
export default generalSlice.reducer;
