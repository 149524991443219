/* eslint-disable react/require-default-props */
import React from 'react';
import { SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { newsEventList } from 'assets/data';
import HeadingSection from 'components/molecules/HeadingSection';
import Container from 'components/organisms/Container';
import Events from 'components/organisms/Events';
import useDeviceQueries from 'hooks/useDeviceQueries';

export interface CarouselProps extends SwiperOptions {
  id: number;
  useBanner?: boolean;
  navigationInside?: boolean;
}

const EventsList: React.FC = () => (
  <>
    {
    newsEventList.map((d, i) => (
      <Events
        key={`i-${i.toString()}`}
        imgSrc={d.img}
        imgSrc2={d.img2}
      />
    ))
  }
  </>
);

const Carousel: React.FC<CarouselProps> = ({
  id,
  children,
  useBanner,
  navigationInside,
  ...innerProps
}) => (
  <div className="t-newsEventsList_carousel">
    <Swiper
      className="t-newsEventsList_swiper"
      pagination={false}
      navigation={false}
      grabCursor
      watchOverflow
      {...innerProps}
    >
      {React.Children.map(children, (child, index) => (
        <SwiperSlide key={`i-${index.toString()}`}>
          <div className="t-newsEventsList_carousel_content">{child}</div>
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
);

const NewsEventsList: React.FC = () => {
  const { isMobile } = useDeviceQueries();
  return (
    <div className="t-newsEventsList">
      <Container>
        <div className="t-newsEventsList_wrapper">
          <div className="t-newsEventsList_heading">
            <HeadingSection>
              DANH SÁCH SỰ KIỆN
            </HeadingSection>
          </div>
          <div className="t-newsEventsList_content">
            {isMobile ? (
              <Carousel
                id={3}
                centerInsufficientSlides
                slidesPerView={1.2}
                spaceBetween={8}
                freeMode
                breakpoints={{}}
              >
                {
                  newsEventList.map((m, i) => (
                    <Events
                      key={`event-${i.toString()}`}
                      imgSrc={m.img}
                      imgSrc2={m.img2}
                    />
                  ))
                }
              </Carousel>
            ) : <EventsList />}
          </div>
        </div>
      </Container>
    </div>
  );
};

NewsEventsList.defaultProps = {
};

export default NewsEventsList;
