import React from 'react';

import logo from 'assets/images/numberChoosing/logo-double.png';
import backgroundMobile from 'assets/images/numberChoosing/numberLogin-mobile.png';
import background from 'assets/images/numberChoosing/numberLogin.jpg';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';

interface CheckInProps {
  handleScanQr?: () => void;
  handleCheckPhoneNumber?: () => void;
}

const CheckIn: React.FC<CheckInProps> = ({
  handleScanQr, handleCheckPhoneNumber,
}) => (
  <div className="t-checkIn">
    {/* LOGO  */}
    <div className="t-checkIn_logo">
      <img src={logo} alt="logo" />
    </div>
    {/* BACKGROUND  */}
    <div className="t-checkIn_background">
      <Image
        imgSrc={background}
        srcMobile={backgroundMobile}
        srcTablet={backgroundMobile}
        size="cover"
        alt="background"
        ratio="1368x768"
      />
    </div>
    <div className="t-checkIn_form">
      <div className="t-checkIn_form_title">
        <Button
          color="pigment"
          variant="secondary"
          size="sm"
          className="active"
        >
          <span>HỘI NGHỊ GẮN BÓ</span>
          <span>KHÁCH HÀNG</span>
          <span>CLP 2022</span>
        </Button>
      </div>
      <br />
      <Text modifiers={['pigment', '700', '20x24', 'center', 'uppercase']}>
        Checkin
      </Text>
      <div className="t-checkIn_button">
        <Button
          variant={['h50', 'uppercase']}
          type="submit"
          handleClick={handleScanQr}
        >
          quét mã Qr
        </Button>
        <Button
          variant={['h50', 'uppercase']}
          type="submit"
          handleClick={handleCheckPhoneNumber}
        >
          Số điện thoại
        </Button>
      </div>
    </div>
  </div>
);

CheckIn.defaultProps = {
  handleScanQr: undefined,
  handleCheckPhoneNumber: undefined,
};

export default CheckIn;
