import img022Src from 'assets/images/events/event-04-2.png';
import img02Src from 'assets/images/events/event-04.jpg';
import img012Src from 'assets/images/events/event-05-2.png';
import img01Src from 'assets/images/events/event-05.jpg';
import instruction1MobileImg from 'assets/images/instruction/instruction1-mobile.jpg';
import instruction1Img from 'assets/images/instruction/instruction1.jpg';
import instruction2MobileImg from 'assets/images/instruction/instruction2-mobile.jpg';
import instruction2Img from 'assets/images/instruction/instruction2.jpg';
import instruction3MobileImg from 'assets/images/instruction/instruction3-mobile.jpg';
import instruction3Img from 'assets/images/instruction/instruction3.jpg';
import instruction4MobileImg from 'assets/images/instruction/instruction4-mobile.jpg';
import instruction4Img from 'assets/images/instruction/instruction4.jpg';
import instruction5MobileImg from 'assets/images/instruction/instruction5-mobile.jpg';
import instruction5Img from 'assets/images/instruction/instruction5.jpg';
import { NumberListTypes } from 'components/templates/NumberPickingTemplate';

const instructionDataImage = [
  {
    step: 1,
    pc: instruction1Img,
    mobile: instruction1MobileImg,
  },
  {
    step: 2,
    pc: instruction2Img,
    mobile: instruction2MobileImg,
  },
  {
    step: 3,
    pc: instruction3Img,
    mobile: instruction3MobileImg,
  },
  {
    step: 4,
    pc: instruction4Img,
    mobile: instruction4MobileImg,
  },
  {
    step: 5,
    pc: instruction5Img,
    mobile: instruction5MobileImg,
  },
];

export const ballListDummy: NumberListTypes[] = [
  {
    isChosen: false,
    value: '401',
    checked: false,
  },
  {
    isChosen: false,
    value: '402',
    checked: false,
  },
  {
    isChosen: true,
    value: '403',
    checked: false,
  },
];

export const newsEventList = [
  {
    img: img01Src,
    img2: img012Src,
  },
  {
    img: img02Src,
    img2: img022Src,
  },
];

export default instructionDataImage;
