/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { throttle } from 'lodash-es';
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import floating from './floating';

import { BallVariantTypes } from 'components/molecules/Ball';
import { EmojiIconTypes } from 'components/organisms/Emoji';
import Livestream, { PrizeListTypes } from 'components/templates/Livestream';
import { BallType } from 'components/templates/PickingBall';
import { useDidMount } from 'helpers/react-hook';
import useWindowDimensions from 'hooks/useWindowDimension';
import { getLocalStorage, setLocalStorage } from 'services/common/storage';
import { pressEmojiService } from 'services/member';
import { GetMemberResponse } from 'services/member/types';
import { getPrizesService } from 'services/picking';
import { LuckyDrawsSocketTypes } from 'services/picking/type';
import trackingOnlineService from 'services/tracking';
import { TrackingOnlineDataTypes } from 'services/tracking/type';
import { getConfigsLiveStreamAction } from 'store/configs';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMeAction } from 'store/me';
import SLUG_PARAMS from 'utils/constants';
import { numberWithCommas } from 'utils/functions';

const LivestreamContainer: React.FC<{ memberData: GetMemberResponse }> = ({
  memberData,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { livestream } = useAppSelector((state) => state.configs);
  const { data: meData } = useAppSelector((state) => state.me);
  const { luckyDrawsSocket, emojiSocket } = useAppSelector((state) => state.general);
  const interactRef = useRef<NodeJS.Timeout>();
  const [fullScreen, setFullscreen] = useState(false);
  const [dataPrize, setDataPrize] = useState<PrizeListTypes[]>([]);
  const [dataBall, setDataBall] = useState<BallType[]>(
    memberData.lucky_draws.map((val) => ({
      value: (val.number_choosed).toString(),
      variant: 'active' as BallVariantTypes,
    })),
  );
  const { width: winWidth } = useWindowDimensions();

  useEffect(() => {
    dispatch(getConfigsLiveStreamAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * GET PRIZE DATA FROM API
   */
  useEffect(() => {
    (async () => {
      const res = await getPrizesService();
      const resball = await getPrizesService(1);
      const prizeMe = resball.find((item) => item.event_time_code === meData?.event_time_code);
      const numberArr = !prizeMe ? [] : prizeMe.details.filter((
        item,
      ) => !item.member_previously_won).map((it) => it.number_choosed);
      const bingoNumber = Array.prototype.concat(...numberArr);

      // Set ball bingo if it has exist in API
      setDataBall((prevState) => prevState.map(
        (val) => (bingoNumber.includes(parseInt(val.value, 10))
          ? {
            ...val,
            variant: 'bingo',
          }
          : val),
      ));
      // PRIZE LIST DATA MODIFIER
      setDataPrize(res.map((val) => ({
        id: val.id,
        title: `${val.name}: ${val.number_prize} giải`,
        isSpecial: val.number_prize === 1,
        data: val.details.filter((item) => !item.member_previously_won).map((item) => ({
          id: item.member_id,
          name: item.member.shop_name,
          address: item.member.address || '-',
          number: item.number_choosed,
        })),
      })));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * HANDLE INTERACTION
   */
  // const removeFirstItem = () => {
  //   if (interactRef.current) return;
  //   interactRef.current = setInterval(() => {
  //     setInteraction((prev) => prev.filter((x, i) => i !== 0));
  //   }, 1000);
  // };
  // useEffect(() => {
  //   if (interaction.length < 1 && interactRef.current) {
  //     clearInterval(interactRef.current);
  //     interactRef.current = undefined;
  //   }
  // }, [interaction]);

  const handleCommonInteraction = useCallback((data: EmojiIconTypes) => {
    if (data.idUser !== meData?.id) {
      const resHeight = winWidth && winWidth <= 991 ? '62vh' : '74vh';
      const resWidth = winWidth && winWidth <= 991 ? '63vw' : '68vw';

      floating({
        content: `<div class="emoji-img ${data.name}" />`,
        number: 1,
        duration: 5,
        rep: '1',
        size: 2,
        containerWidth: fullScreen ? '100%' : resWidth,
        containerHeight: fullScreen ? '100vh' : resHeight,
      });
    }
  }, [fullScreen, winWidth]);

  const handleInteraction = async (data: EmojiIconTypes) => {
    const { icon, ...dataWithoutIcon } = data;
    await pressEmojiService({ ...dataWithoutIcon, idUser: meData?.id });

    handleCommonInteraction(data);
  };

  const throttledInteraction = throttle(handleInteraction, 200, {
    trailing: false,
    leading: true,
  });

  /**
     * SOCKET LISTENER
     */
  const socketGetLuckyNumber = (data: LuckyDrawsSocketTypes) => {
    setTimeout(() => {
      const luckyData = data.luckyDraw;
      setDataBall((prevState) => prevState.map(
        (val) => (luckyData.number_choosed === parseInt(val.value, 10)
        && meData?.event_time_code === luckyData.event_time_code
        && meData.id === luckyData.member_id
          ? {
            ...val,
            variant: 'bingo',
          }
          : val),
      ));

      setDataPrize((prevState) => prevState.map((val) => {
        if (val.id === luckyData.prize_id && !data.previousRoundWon) {
          return {
            ...val,
            data: [...val.data, {
              id: luckyData.member_id,
              name: luckyData.member.shop_name,
              address: luckyData.member.address || '-', // replace with address field later
              number: luckyData.number_choosed,
            }],
          };
        }
        return val;
      }));
    }, 12000);
  };

  // useDidMount(() => {
  //   const channel = echo.private(`EventTime.${memberData.event_time_code}`);

  //   channelRef.current = channel;

  //   const handleEmojiWhisper = (data: EmojiIconTypes) => {
  //     handleCommonInteraction(data);
  //   };

  //   channel.listen('LuckyNumberResult', socketGetLuckyNumber);
  //   channel.listenForWhisper('emoji', handleEmojiWhisper);

  //   return () => {
  //     channel.stopListening('LuckyNumberResult', socketGetLuckyNumber);
  //     channel.stopListeningForWhisper('emoji', handleEmojiWhisper);
  //   };
  // });

  useEffect(() => {
    if (luckyDrawsSocket.length > 0) {
      const lastData = luckyDrawsSocket[0];
      socketGetLuckyNumber(lastData);
    }
  }, [luckyDrawsSocket]);

  useEffect(() => {
    if (emojiSocket.length > 0) {
      const lastData = emojiSocket[0];
      handleCommonInteraction(lastData);
    }
  }, [emojiSocket, handleCommonInteraction]);

  const dataBallFilter = useMemo(() => {
    const bingo = dataBall.filter((f) => f.variant === 'bingo');
    const normal = dataBall.filter((f) => f.variant === 'active');
    const result = bingo.concat(normal);
    return result;
  }, [dataBall]);

  /**
   *  TRACKING ONLINE
   */

  const getDateTimeTracking = (): string => {
    const today = new Date();
    return `${today.getFullYear()}-${
      today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1
    }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()} ${
      today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()
    }:${
      today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()
    }:${today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds()}`;
  };
  const sendTracking = async (data: TrackingOnlineDataTypes[]) => {
    if (!data) return;
    try {
      const minuteSaved = getLocalStorage('currentWatchLive');
      await trackingOnlineService({
        data_tracking: data,
        current_minutes_watched: minuteSaved ? parseInt(minuteSaved, 10) : undefined,
      });
    } catch {
      // TODO: error
    }
  };

  useEffect(() => {
    let counter = 0;
    const trackingSaved = getLocalStorage('trackingData');
    const trackingArr: TrackingOnlineDataTypes[] = trackingSaved
      ? JSON.parse(trackingSaved)
      : [];

    const interval = setInterval(() => {
      counter += 1;
      if (counter === 3) {
        trackingArr.push({ zone: 2, time_tracking: getDateTimeTracking() });
        sendTracking(trackingArr);
        counter = 0;
        trackingArr.splice(0, trackingArr.length);
      } else {
        trackingArr.push({ zone: 2, time_tracking: getDateTimeTracking() });
        setLocalStorage('trackingData', JSON.stringify(trackingArr));
      }
    }, 60000);
    return () => {
      sendTracking(trackingArr);
      getLocalStorage('trackingData');
      clearInterval(interval);
    };
  }, []);

  if (!meData) return <>loading</>;

  return (
    <Livestream
      meData={meData}
      storeName={memberData.name}
      handleFullscreen={(isFUll) => setFullscreen(isFUll)}
      handleInteract={(data) => {
        throttledInteraction(data);
      }}
      handleBack={() => history.push(SLUG_PARAMS.FOYER)}
      prizeListData={dataPrize}
      // prizeListData={[...dataPrize].reverse()}
      numberList={dataBallFilter}
      livestreamConfig={{
        link_livestream: livestream?.link_livestream || '',
        theoplayer_license: process.env.REACT_APP_THEOPLAYER_LICENSE || '',
        theoplayer_library_location: process.env.REACT_APP_THEOPLAYER_LIBRARY_LOCATION || '',
      }}
    />
  );
};

const LivestreamContainerWrapper: React.FC = () => {
  const { data: memberData } = useAppSelector((state) => state.me);
  if (!memberData) {
    return null;
  }
  return <LivestreamContainer memberData={memberData} />;
};

export default LivestreamContainerWrapper;
