/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PopupAllBall from './popup-selectedball';
import PopupTimeout from './popup-timeout';

import Text from 'components/atoms/Text';
import { BallVariantTypes } from 'components/molecules/Ball';
import { TimeTypes } from 'components/molecules/Countdown';
import ConfirmNumberPopup from 'components/templates/ConfirmNumberPopup';
import ContextPopup from 'components/templates/ContextPopup';
import PickingBall, { BallType } from 'components/templates/PickingBall';
import { GetMemberResponse } from 'services/member/types';
import getNumberSelectedService, { postConfirmNumberSelection, postSelectedNumberService } from 'services/picking';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMeAction } from 'store/me';
import SLUG_PARAMS, { NUMBER_PICKING_TIMER } from 'utils/constants';
import { randomNumberInRange } from 'utils/functions';

const PickingBallContainer: React.FC<{memberData: GetMemberResponse }> = ({ memberData }) => {
  const { numberSocket, resetNumberSocket } = useAppSelector((state) => state.general);
  /**
   * -------------------STATE------------------
   */
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [activeBall, setActiveBall] = useState<BallType[]>([]);
  const [isError, setError] = useState(false);
  const [timeState, setTimeState] = useState<number | undefined>(NUMBER_PICKING_TIMER);
  const [errMessage, setErrMessage] = useState<string>();
  const [timeRemaining, setTimeRemaining] = useState<TimeTypes>();
  const [loadingPicking, setLoadingPicking] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [keySlide, setKeySlide] = useState(0);
  const [checkFilter, setCheckFilter] = useState(false);
  const [openPopup, setOpenPopup] = useState({
    confirm: false,
    timeout: false,
    review: false,
    notify: false,
  });
  const [loading, setLoading] = useState(false);

  const LIMIT_BALL = memberData?.number_lucky || 0;
  const temp = [...Array(memberData.event_time.range_draw.range_end).fill(0)];
  const [searchResult, setSearchResult] = useState<BallType[]>(
    temp.map((_, i) => ({ value: (i + 1).toString(), variant: 'empty', visible: true })),
  );
  const [tempResult, setTempResult] = useState<BallType[]>(searchResult);

  /**
   * -------------------HOOK------------------
   */

  // Get Number selected before
  useEffect(() => {
    (async () => {
      try {
        const res = await getNumberSelectedService();
        const clone = searchResult.slice();
        setSearchResult(clone.map(
          (_, i) => ({
            value: (i + 1).toString(),
            variant: res.other_selected_numbers.includes(i + 1) ? 'disabled' : 'empty',
            visible: true,
          }),
        ));
        setTempResult(clone.map(
          (_, i) => ({
            value: (i + 1).toString(),
            variant: res.other_selected_numbers.includes(i + 1) ? 'disabled' : 'empty',
            visible: true,
          }),
        ));
      } catch (error) {
        const errors = error as ErrorResponse[];
        if (errors.length) {
          switch (errors[0].code) {
            case 'E_1010':
              history.push(SLUG_PARAMS.NUMBER_CONGRATULATION);
              break;
            case 'E_1013':
              history.push(SLUG_PARAMS.NUMBER_CONGRATULATION);
              break;
            default:
              setErrMessage('Có lỗi xảy ra! Vui lòng tải lại trang!');
              setOpenPopup((prevState) => ({
                ...prevState,
                notify: true,
              }));
          }
        }
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get me profile
  useEffect(() => {
    if (!memberData) dispatch(getMeAction());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setKeySlide(randomNumberInRange(1, 100));
  }, [searchValue]);

  useEffect(() => {
    if (checkFilter) {
      setSearchResult((prevState) => prevState.filter((f) => f.variant !== 'disabled'));
    } else setSearchResult(tempResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkFilter]);

  // useEffect(() => {
  //   if (!checkFilter) setTempResult(searchResult);
  // }, [searchResult, checkFilter]);

  /**
   * ------------FUNCTION------------------
   */

  // Click ball handler
  const onClickBall = async (data?: BallType) => {
    if (!data) return;
    setLoadingPicking(true);
    try {
      await postSelectedNumberService({
        number: parseInt(data.value, 10),
        select: data?.variant === 'empty',
      });

      if (data && !activeBall?.find((e) => e.value === data?.value)) {
        setActiveBall([...activeBall, {
          value: data.value,
          variant: 'active',
        }]);
      } else {
        setActiveBall(activeBall.filter((e) => e.value !== data?.value));
      }

      setSearchResult((prev) => prev.map((e) => {
        if (e.value === data?.value) {
          if (e.variant === 'empty') {
            return ({
              ...e,
              variant: 'active',
              visible: true,
            });
          }
          return ({
            ...e,
            variant: 'empty',
            visible: true,
          });
        }
        return e;
      }));
      setTempResult((prev) => prev.map((e) => {
        if (e.value === data?.value) {
          if (e.variant === 'empty') {
            return ({
              ...e,
              variant: 'active',
              visible: true,
            });
          }
          return ({
            ...e,
            variant: 'empty',
            visible: true,
          });
        }
        return e;
      }));
      setError(false);
    } catch (error) {
      const errors = error as ErrorResponse[];
      if (errors.length) {
        switch (errors[0].code) {
          case 'E_1011':
            setSearchResult(
              (prevState) => prevState.map((item) => (
                parseInt(item.value, 10) !== parseInt(data.value, 10)
                  ? item : {
                    value: item.value,
                    variant: 'disabled',
                    visible: true,
                  })),
            );
            setTempResult(
              (prevState) => prevState.map((item) => (
                parseInt(item.value, 10) !== parseInt(data.value, 10)
                  ? item : {
                    value: item.value,
                    variant: 'disabled',
                    visible: true,
                  })),
            );
            break;
          case 'E_1010':
            setErrMessage('Quý khách đã chọn đủ số dự thưởng. Vui lòng bấm xác nhận chọn số.');
            setOpenPopup((prevState) => ({
              ...prevState,
              notify: true,
            }));
            break;
          default:
            setErrMessage('Có lỗi xảy ra! Vui lòng tải lại trang!');
            setOpenPopup((prevState) => ({
              ...prevState,
              notify: true,
            }));
            window.location.reload();
        }
      }
    } finally {
      setLoadingPicking(false);
    }
  };

  // Search ball
  const onSearch = ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (value) {
      setSearchResult((prevState) => prevState.map((k) => (k.value.includes(value)
        ? {
          ...k,
          visible: true,
        } : {
          ...k,
          visible: false,
        })));
      setTempResult((prevState) => prevState.map((k) => (k.value.includes(value)
        ? {
          ...k,
          visible: true,
        } : {
          ...k,
          visible: false,
        })));
    } else {
      setSearchResult((prevState) => prevState.map((k) => ({
        ...k,
        visible: true,
      })));
      setTempResult((prevState) => prevState.map((k) => ({
        ...k,
        visible: true,
      })));
    }
  };

  // Review Selected Ball Popup
  const handleReview = () => {
    if (activeBall?.length !== LIMIT_BALL) {
      setError(true);
    } else {
      setOpenPopup((prevState) => ({
        ...prevState,
        confirm: true,
      }));
    }
  };

  // Popup Timeout
  const handleTimeout = () => {
    setTimeState(undefined);
    setOpenPopup((prev) => ({
      ...prev,
      timeout: true,
      review: false,
      confirm: false,
    }));
  };

  const handleRestart = () => {
    setTimeState(NUMBER_PICKING_TIMER);
    const updatedCheckedState = searchResult.map(
      (item) => ({
        ...item,
        variant: 'empty' as BallVariantTypes,
      }),
    );
    setOpenPopup((prev) => ({
      ...prev,
      timeout: false,
    }));
    setSearchResult(updatedCheckedState);
    setTempResult(updatedCheckedState);
    setActiveBall([]);
    window.location.reload();
  };

  useEffect(() => {
    if (openPopup.timeout) {
      setTimeout(() => {
        handleRestart();
      }, 5000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPopup.timeout]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const numberArr = activeBall.map((val) => parseInt(val.value, 10));
      await postConfirmNumberSelection({
        numbers: numberArr,
      });
      dispatch(getMeAction());
      // history.push(SLUG_PARAMS.NUMBER_CONGRATULATION);
    } catch (error) {
      const errors = error as ErrorResponse[];
      if (errors.length) {
        switch (errors[0].code) {
          case 'E_1010':
            setErrMessage(errors[0].title);
            setOpenPopup((prevState) => ({
              ...prevState,
              notify: true,
            }));
            break;
          default:
            setErrMessage('Có lỗi xảy ra! Vui lòng tải lại trang!');
            setOpenPopup((prevState) => ({
              ...prevState,
              notify: true,
            }));
        }
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle Time remaing
  const handleDuration = (data: TimeTypes) => {
    if (openPopup.confirm) {
      setTimeRemaining(data);
    } else setTimeRemaining(undefined);
  };

  /**
   * SOCKET LISTENER EVENT
   * data: {value: number; check: boolean}
   */

  const socketNumberSelection = (data: any) => {
    setSearchResult((prevState) => prevState.map((item) => (parseInt(item.value, 10) !== data.number
      ? item : {
        value: item.value,
        variant: data.select ? 'disabled' : 'empty',
        visible: true,
      })));
    setTempResult((prevState) => prevState.map((item) => (parseInt(item.value, 10) !== data.number
      ? item : {
        value: item.value,
        variant: data.select ? 'disabled' : 'empty',
        visible: true,
      })));
  };

  const socketResetNumberSelection = (data: {numbers: number[]}) => {
    setSearchResult((prevState) => prevState.map(
      (item) => (data.numbers.includes(parseInt(item.value, 10)) ? {
        value: item.value,
        variant: 'empty',
        visible: true,
      } : item),
    ));
    setTempResult((prevState) => prevState.map(
      (item) => (data.numbers.includes(parseInt(item.value, 10)) ? {
        value: item.value,
        variant: 'empty',
        visible: true,
      } : item),
    ));
  };
  // Socket
  // useDidMount(() => {
  //   const channel = echo.private(`EventTime.${memberData.event_time_code}`);
  //   channel.listen('NumberSelection', socketNumberSelection);
  //   channel.listen('ResetNumberSelection', socketResetNumberSelection);
  //   return () => {
  //     channel.stopListening('NumberSelection', socketNumberSelection);
  //     channel.stopListening('ResetNumberSelection', socketResetNumberSelection);
  //   };
  // });

  useEffect(() => {
    if (numberSocket.length > 0) {
      socketNumberSelection(numberSocket[0]);
    }
  }, [numberSocket]);

  useEffect(() => {
    if (resetNumberSocket) {
      socketResetNumberSelection(resetNumberSocket);
    }
  }, [resetNumberSocket]);

  return (
    <>
      <PickingBall
        loadingPicking={loadingPicking}
        timer={timeState}
        searchValue={searchValue}
        ball={searchResult}
        activeBall={activeBall}
        keySlide={keySlide}
        isError={isError}
        limitBall={LIMIT_BALL}
        username={memberData?.name || ''}
        handleClick={onClickBall}
        handleSubmit={handleReview}
        handleChange={(e) => {
          setSearchValue(e.currentTarget.value);
          onSearch(e);
        }}
        checkFilter={checkFilter}
        handleCheck={() => setCheckFilter(!checkFilter)}
        handleTimeout={handleTimeout}
        handleDuration={handleDuration}
        handleShowAll={() => setOpenPopup((prev) => ({
          ...prev,
          review: true,
        }))}
      />
      <PopupTimeout
        isShowPopup={openPopup.timeout}
        handleClose={handleRestart}
      />
      <PopupAllBall
        isShowPopup={openPopup.review}
        limitBall={LIMIT_BALL}
        activeBall={activeBall}
        handleClose={() => setOpenPopup((prev) => ({
          ...prev,
          review: false,
        }))}
      />
      {/* Confirm Picked Number  */}
      <ConfirmNumberPopup
        activeBallList={activeBall}
        limitNumber={LIMIT_BALL}
        openPopup={openPopup.confirm}
        timer={timeRemaining}
        handleSubmit={handleSubmit}
        handleClose={() => setOpenPopup((prev) => ({
          ...prev,
          confirm: false,
        }))}
        loading={loading}
      />

      {/* Notify  */}
      <ContextPopup
        openPopup={openPopup.notify}
        handleClose={() => setOpenPopup((prevstate) => ({
          ...prevstate,
          notify: false,
        }))}
      >
        <Text modifiers={['royalAzure', '400', 'center']}>
          {errMessage}
        </Text>
      </ContextPopup>
    </>
  );
};

const PickingBallWrapper:React.FC = () => {
  const { data: memberData } = useAppSelector((state) => state.me);

  if (!memberData) {
    return null;
  }
  return <PickingBallContainer memberData={memberData} />;
};

export default PickingBallWrapper;
