/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import contentsTopData, { contentsBottomData } from 'assets/dataDummy/guide';
import bannerMobileSrc from 'assets/images/banner-mobile.jpg';
import bannerSrc from 'assets/images/customer.jpg';
import sectionTextureImg from 'assets/images/section-texture.png';
import Button from 'components/atoms/Button';
import Banner from 'components/organisms/Banner';
import Container from 'components/organisms/Container';
import Footer from 'components/organisms/Footer';
import DeviceWrappedVertical from 'components/templates/DeviceWrappedVertical';
import Guide from 'components/templates/Guide';
import NewsEventsList from 'components/templates/NewsEventsList';
import RegisterForm from 'components/templates/RegisterForm';
import ShowContent from 'components/templates/ShowContent';
import useMutedAutoplay from 'hooks/useMutedAutoplay';
import useWindowScroll from 'hooks/useWindowScroll';
import { getDimensions, scrollDownNextSection } from 'utils/functions';

const headerData = ['THỜI GIAN', 'NỘI DUNG'];
const rowData = [
  {
    time: '13:00 - 14:00',
    content: 'Livestream bỏ banh vào lồng cầu',
  },
  {
    time: '14:00 - 15:00',
    content: 'Nghỉ giải lao cho phần quay số',
  },
  {
    time: '15:00 - 15:10',
    content: 'Giới thiệu chương trình & Phát biểu của đại diện Abbott',
  },
  {
    time: '15:10 - 15:15',
    content: 'Giới thiệu cơ cấu giải thưởng',
  },
  {
    time: '15:15 - 15:20',
    content: 'Vinh danh 40 CLP đã đạt giải',
  },
  {
    time: '15:20 - 15:25',
    content: 'Quay số khu vực Hồ Chí Minh',
  },
  {
    time: '15:25 - 15:30',
    content: 'Quay số khu vực miền Tây',
  },
  {
    time: '15:30 - 15:35',
    content: 'Quay số khu vực miền Đông',
  },
  {
    time: '15:35 - 15:40',
    content: 'Quay số khu vực miền Trung',
  },
  {
    time: '15:40 - 15:45',
    content: 'Quay số khu vực Hà Nội',
  },
  {
    time: '15:45 - 15:50',
    content: 'Quay số khu vực miền Bắc',
  },
  {
    time: '15:50 - 16:00',
    content: 'Kết thúc chương trình',
  },
];
const Home: React.FC = () => {
  const [muted, setMuted] = useState(true);
  const [isScroll, setIsScroll] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const bannerRef = useRef<HTMLDivElement>(null);
  const eventsRef = useRef<HTMLDivElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);
  const registerRef = useRef<HTMLDivElement>(null);
  const agendaRef = useRef<HTMLDivElement>(null);
  const guideRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const isMountedRef = useRef(true);
  const [visibleSection, setVisibleSection] = useState('');

  const sectionRefs = [
    { section: 'DANH SÁCH SỰ KIỆN', ref: eventsRef },
    // { section: 'THÔNG TIN hội nghị', ref: infoRef },
    { section: 'đăng ký tham gia', ref: registerRef },
    { section: 'nội dung buổi quay số', ref: agendaRef },
    { section: 'hướng dẫn & liên hệ', ref: guideRef },
  ];

  useMutedAutoplay(videoRef, setMuted);

  // scroll to Active section
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 72;

      const selected = sectionRefs.find(({ ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition + 72 > offsetTop && scrollPosition + 72 < offsetBottom;
        }
        return false;
      });

      if (selected && selected.section !== visibleSection && isMountedRef.current) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection && isMountedRef.current) {
        setVisibleSection('');
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      isMountedRef.current = false;
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // onScrollPlayer
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight / 2 && videoRef.current) {
        videoRef.current.play();
        window.removeEventListener('scroll', handleScroll);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // on scroll fix position menu
  useWindowScroll(() => {
    if (bannerRef.current && window.pageYOffset > bannerRef.current?.clientHeight) {
      setIsScroll(true);
      return;
    }
    setIsScroll(false);
  });

  return (
    <DeviceWrappedVertical requiredVertical>
      <div className="p-home">
        <section id="banner" ref={bannerRef}>
          <Banner
            imageDesktop={bannerSrc}
            imageMobile={bannerMobileSrc}
            imageTablet={bannerSrc}
            title="ĐĂNG KÝ"
            handleClick={() => scrollDownNextSection(registerRef, menuRef.current?.clientHeight)}
            handleClickInfo={() => scrollDownNextSection(infoRef, menuRef.current?.clientHeight)}
          />
        </section>
        <div
          ref={menuRef}
          className={`t-newsEventsList_menu${isScroll ? ' fixed' : ''}`}
        >
          <div className="t-newsEventsList_menu_wrap">
            {
                sectionRefs.map((ele, idx) => (
                  <Button
                    key={`i-${idx.toString()}`}
                    color="pigment"
                    variant="secondary"
                    className={visibleSection === ele.section ? 'active' : ''}
                    handleClick={() => scrollDownNextSection(
                      ele.ref, menuRef.current?.clientHeight,
                    )}
                  >
                    {ele.section}
                  </Button>
                ))
              }
          </div>
        </div>
        <section id="newsEvents" ref={eventsRef} className={isScroll ? ' marginTop' : ''}>
          <NewsEventsList />
        </section>
        {/* <section id="showInfo" ref={infoRef}>
          <div className="t-showInfo">
            <Container>
              <div className="t-showInfo_inner">
                <div className="t-showInfo_title">
                  <HeadingSection>THÔNG TIN CHƯƠNG TRÌNH</HeadingSection>
                </div>
                <div className="t-showInfo_wrapper">
                  <Player
                    isMuted={muted}
                    src={eventConfigs.landingpageVideo}
                    ref={videoRef}
                    isLoop
                  />
                  <div className="t-showInfo_mute" onClick={() => setMuted(!muted)}>
                    <Icon iconName={muted ? 'mute' : 'sound'} iconSize="32x32" />
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </section> */}
        <section id="register" ref={registerRef}>
          <Container>
            <RegisterForm />
          </Container>
          <div className="p-home_texture">
            <img src={sectionTextureImg} alt="texture" />
          </div>
        </section>
        <section id="ShowContent" ref={agendaRef}>
          <ShowContent
            title="nội dung chương trình"
            headerData={headerData}
            rowData={rowData}
          />
        </section>
        <section id="guide" ref={guideRef}>
          <Guide
            contentsTop={contentsTopData}
            contentsBottom={contentsBottomData}
          />
        </section>
        <Footer />
      </div>
    </DeviceWrappedVertical>
  );
};

export default Home;
