import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import HeadingSection from 'components/molecules/HeadingSection';
import Form from 'components/organisms/Form';
import NumberBannerLayout from 'components/organisms/NumberBannerLayout';
import useDebounceInput from 'hooks/useDebounceInput';
import { registerSchema } from 'utils/schemas';

export type ForgetPasswordFormTypes = {
  code: string;
}
interface ForgetPasswordProps {
  name?: string;
  phone?: string;
  loading?: boolean;
  disabled?: boolean;
  validateField?: (code: string) => void;
  handleSubmit?: (data: ForgetPasswordFormTypes) => void;
}

const ForgetPassword: React.FC<ForgetPasswordProps> = ({
  name,
  phone,
  loading,
  disabled,
  validateField,
  handleSubmit,
}) => {
  const method = useForm<ForgetPasswordFormTypes>({
    resolver: yupResolver(registerSchema),
    mode: 'onChange',
  });

  const shopCodeDb = useDebounceInput(method.watch('code'), 500);

  useEffect(() => {
    if (validateField) {
      validateField(shopCodeDb);
    }
  }, [validateField, shopCodeDb]);

  return (
    <>
      <div className="t-forgetPassword">
        <NumberBannerLayout>
          {/* FORM  */}
          <div className="t-forgetPassword_form">
            <div className="t-forgetPassword_form_border">
              <div className="t-forgetPassword_form_heading">
                <HeadingSection modifiers="secondary">
                  quên thông tin đăng nhập
                </HeadingSection>
              </div>
              <div className="t-forgetPassword_form_desc blur">
                <Text modifiers={['pigment', 'sm', 'center']}>
                  Quý khách hàng vui lòng điền mã cửa hàng được dán trên
                  {' '}
                  <br />
                  {' '}
                  thiệp mời vào ô bên dưới
                </Text>
              </div>
              <Form method={method} submitForm={(data) => handleSubmit && handleSubmit(data)}>
                <div className="t-forgetPassword_form_wrap">
                  <div className="t-forgetPassword_form_input">
                    <Controller
                      name="code"
                      render={({ field, fieldState }) => (
                        <Input
                          {...field}
                          id="code"
                          modifiers="light"
                          lbBold
                          label="Nhập mã cửa hàng"
                          value={field.value}
                          error={fieldState?.error?.message}
                          handleChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                  <div className="t-forgetPassword_form_desc">
                    <Text modifiers={['pigment', 'sm', 'center']}>
                      Vui lòng kiểm tra thông tin tên cửa hàng và số điện thoại
                    </Text>
                  </div>
                  <div className="t-forgetPassword_form_input mt-24">
                    <Input
                      id="name"
                      noCursor
                      lbBold
                      modifiers="light"
                      value={name}
                      disabled
                      label="Tên cửa hàng"
                    />
                  </div>
                  <div className="t-forgetPassword_form_input mt-24">
                    <Input
                      id="phone"
                      noCursor
                      lbBold
                      modifiers="light"
                      value={phone}
                      disabled
                      label="Số điện thoại"
                    />
                  </div>
                  <div className="t-forgetPassword_form_button">
                    <Button
                      loading={loading}
                      type="submit"
                      size="sm"
                      disabled={disabled}
                    >
                      GỬI LẠI THÔNG TIN
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </NumberBannerLayout>
      </div>

    </>
  );
};

ForgetPassword.defaultProps = {
  name: undefined,
  phone: undefined,
  loading: false,
  disabled: false,
  validateField: undefined,
  handleSubmit: undefined,
};

export default ForgetPassword;
