import React from 'react';

import InvitationCard from 'components/templates/InvitationCard';
import { useAppSelector } from 'store';

const Invitation: React.FC = () => {
  // const history = useHistory();
  // const dispatch = useAppDispatch();
  // const eventTimeCode = useAppSelector((state) => state.me.data?.event_time_code);
  const { data: profileData } = useAppSelector((state) => state.me);
  /**
   * SOCKET
   */
  // const socketLivestream = useCallback(() => {
  //   dispatch(getMeAction()).unwrap().then(() => {
  //     history.push(SLUG_PARAMS.LIVESTREAM);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // useEffect(() => {
  //   if (!eventTimeCode) {
  //     return;
  //   }
  //   const channel = echo.private(`EventTime.${eventTimeCode}`);
  //   channel.listen('updated', socketLivestream);
  //   // eslint-disable-next-line consistent-return
  //   return () => {
  //     channel.stopListening('updated', socketLivestream);
  //   };
  // }, [eventTimeCode, socketLivestream]);
  return (
    <div className="t-invitation">
      <InvitationCard
        profile={profileData}
      />
    </div>
  );
};

export default Invitation;
