import React, { useEffect, useState } from 'react';

import { dataHeaderSecondaryTable } from 'assets/dataDummy/table';
// import bgPrizeTable from 'assets/images/prizes/bg-prizeTable.jpg';
import prizeLabelImg from 'assets/images/prizes/title.png';
import prizeValueImg from 'assets/images/prizes/title_bottom.png';
import Text from 'components/atoms/Text';
// import Container from 'components/organisms/Container';
import Table, { TableCell, TableHeader, TableRow } from 'components/organisms/Table';
import { PagingTypes } from 'containers/Prize';
import mapModifiers from 'utils/functions';

export type PrizeTableTypes = {
  id: number;
  name: string;
  address: string;
  number: number;
  area?: string;
  phone: string;
  showed?: boolean;
  hasDestroy: boolean;
}

export type PrizeInfoTypes = {
  number: number;
  title: string;
  value: string;
}

interface PrizeTableProps {
  prizeData?: PrizeTableTypes[];
  prizeInfo?: PrizeInfoTypes;
  splitTable?: number;
  recordNumber: number;
  pagingData?: PagingTypes;
}

const PrizeTable: React.FC<PrizeTableProps> = ({
  prizeData, pagingData,
  prizeInfo, splitTable = 5, recordNumber,
}) => {
  const [split, setSplit] = useState(0);
  const tableLeft = [...Array(recordNumber)].slice(split, split * 2);
  const tableLeftPage = [...Array(recordNumber)].slice(
    (pagingData?.startIndex || 0) + (split - 1) + 1, (pagingData?.endIndex || 0) + 1,
  );
  const dataLeft = pagingData ? tableLeftPage : tableLeft;
  const dataStart = [...Array(recordNumber)]?.slice(0, split);

  useEffect(() => {
    if (splitTable) setSplit(splitTable);
  }, [splitTable]);

  useEffect(() => {
    if (dataStart.length !== dataLeft.length && recordNumber >= 30) {
      setSplit((dataStart.length + dataLeft.length) / 2);
    }
  }, [dataStart, dataLeft, recordNumber]);

  return (
    <div
      className={mapModifiers('t-prizeTable',
        prizeData && prizeData?.length < 1 && 'empty',
        (recordNumber > split) && 'double')}
    >
      <div className="t-prizeTable_wrap">
        {/* <Container fullScreen> */}
        {
            prizeInfo
            && (
              <div className="t-prizeTable_top">
                <div className="t-prizeTable_top_item">
                  <div className="t-prizeTable_label">
                    <img src={prizeLabelImg} alt="label" />
                    <Text modifiers={['24x33', '700', 'bluePrize', 'uppercase', 'blueLinear']}>
                      {prizeInfo.title}
                    </Text>
                  </div>
                </div>
                <div className="t-prizeTable_top_item">
                  <div className="t-prizeTable_value">
                    <img src={prizeValueImg} alt="value" />
                    <div className="t-prizeTable_value_text">
                      <Text modifiers={['80x90', '700', 'white', 'center']}>
                        {prizeInfo.number}
                      </Text>
                      <Text modifiers={['20x24', '400', 'white', 'center']}>
                        GIẢI
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        <div className="t-prizeTable_list">
          <div className="t-prizeTable_list_item">
            <Table
              modifiers="secondary"
              header={(
                <TableHeader>
                  <TableRow isHead>
                    {dataHeaderSecondaryTable.map((e, i) => (
                      <TableCell isHead key={`tableCell-${i.toString()}`}>
                        <Text modifiers={['19x26', 'uppercase', '700', 'white', 'center']}>
                          {e.value}
                        </Text>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHeader>
                )}
            >
              {dataStart.map((_, index) => (
                <TableRow key={`tableRow-${index.toString()}`}>
                  <TableCell>
                    <Text modifiers={['16x22', 'uppercase', 'pigment', '700', 'center']}>
                      {pagingData ? pagingData.startIndex + (index + 1) : index + 1}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text modifiers={['16x22', '700', 'pigment', 'uppercase', 'center']}>
                      {prizeData && prizeData.length > 0 && prizeData[index] ? prizeData[index].number : '-'}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text modifiers={['16x22', '700', 'pigment', 'uppercase', 'center']}>
                      {prizeData && prizeData.length > 0 && prizeData[index] ? prizeData[index].name : '-'}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text modifiers={['16x22', 'uppercase', '400', 'center']}>
                      {prizeData && prizeData.length > 0 && prizeData[index] ? prizeData[index].address : '-'}
                    </Text>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </div>
          {
              prizeData && recordNumber && recordNumber > splitTable
              && (
                <div className="t-prizeTable_list_item">
                  <Table
                    modifiers="secondary"
                    header={(
                      <TableHeader>
                        <TableRow isHead>
                          {dataHeaderSecondaryTable.map((e, i) => (
                            <TableCell isHead key={`tableCell-${i.toString()}`}>
                              <Text modifiers={['19x26', 'uppercase', '700', 'white', 'center']}>
                                {e.value}
                              </Text>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHeader>
                    )}
                  >
                    {dataLeft.map(
                      (_, index) => (
                        <TableRow key={`tableRow-${index.toString()}`}>
                          <TableCell>
                            <Text modifiers={['16x22', 'uppercase', 'pigment', '700', 'center']}>
                              {pagingData ? pagingData.startIndex + index + split + 1
                                : index + split + 1}

                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text modifiers={['16x22', '700', 'pigment', 'uppercase', 'center']}>
                              {prizeData && prizeData.length > 0 && prizeData[index + split] ? prizeData[index + split].number : '-'}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text modifiers={['16x22', '700', 'pigment', 'uppercase', 'center']}>
                              {prizeData && prizeData.length > 0 && prizeData[index + split] ? prizeData[index + split].name : '-'}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text modifiers={['16x22', 'uppercase', '400', 'center']}>
                              {prizeData && prizeData.length > 0 && prizeData[index + split] ? prizeData[index + split].address : '-'}
                            </Text>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </Table>
                </div>
              )
            }
        </div>
        {/* </Container> */}
      </div>
    </div>
  );
};

PrizeTable.defaultProps = {
  prizeData: undefined,
  prizeInfo: undefined,
  splitTable: undefined,
  pagingData: undefined,
};

export default PrizeTable;
