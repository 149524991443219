import React from 'react';

import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import HeadingSection from 'components/molecules/HeadingSection';
import NumberBannerLayout from 'components/organisms/NumberBannerLayout';
import { GetMemberResponse } from 'services/member/types';

interface InvitationCardProps {
  profile?: GetMemberResponse;
}
interface InvitationInfoProps {
  label?: string;
  value?: string;
}

const InvitationInfo: React.FC<InvitationInfoProps> = ({
  label, value,
}) => (
  <div className="t-invitationCard_info_item">
    <Text modifiers={['400', 'jet', 'fontUrmAvo', '16x22', 'center']}>
      {label}
      {' '}
      <Text type="span" modifiers={['700', 'pigment', 'fontUrmAvo', '16x22']}>
        {value}
      </Text>
    </Text>
  </div>
);

const InvitationCard: React.FC<InvitationCardProps> = ({
  profile,
}) => (
  <div className="t-invitationCard">
    <NumberBannerLayout>
      <div className="t-invitationCard_panel">
        <div className="t-invitationCard_panel_wrap">
          <div className="t-invitationCard_heading">
            <HeadingSection modifiers="secondary">
              CHÀO MỪNG QUÝ KHÁCH HÀNG
              {' '}
              {profile?.name}
              {' '}
              ĐẾN VỚI CHƯƠNG TRÌNH Quay số trúng thưởng chuyến tham quan và
              xem đội bóng Real Madrid thi đấu tại Tây Ban Nha 2024
            </HeadingSection>
          </div>
          <div className="t-numberCongrate_content">
            {/* <Text modifiers={['700', 'pigment', 'fontUrmAvo', 'center', '16x22']}>
              Thông tin tham dự chương trình bỏ banh vào lồng cầu
            </Text> */}
            <div className="t-numberCongrate_info">
              <InvitationInfo
                label="Quý khách hàng dùng tài khoản đăng nhập:"
                value={profile?.account}
              />
              <Text modifiers={['400', 'jet', 'fontUrmAvo', '16x22', 'center']}>
                để theo dõi chương trình quay hình trực tiếp bỏ banh vào lồng cầu sẽ
                diễn ra lúc
                {' '}
                <p>
                  <strong>
                    {profile?.event_time.show_time_start}
                    {' '}
                  </strong>
                </p>
                {' '}
                tại trang
                {' '}
                <a href="https://hoinghikhachhangclp.com">
                  <strong>https://hoinghikhachhangclp.com</strong>
                </a>
              </Text>
              {/* <InvitationInfo
                label="Thời gian bắt đầu bỏ banh:"
                value={profile?.event_time.show_time_start}
              /> */}
              {/* <InvitationInfo
                label="Địa điểm tổ chức quay số:"
                value={profile?.event_time.address}
              /> */}
            </div>
          </div>
          <div className="u-mt-16">
            <Text modifiers={['400', 'jet', 'fontUrmAvo', '16x22', 'center']}>
              Mọi thắc mắc xin vui lòng liên hệ tổng đài:
              {' '}
              <Link href="tel:0918454049" useExternal>
                <Text type="span" modifiers={['700', 'jet', 'fontUrmAvo', '16x22']}>
                  0918 45 40 49
                </Text>
              </Link>
            </Text>
          </div>
          <div className="u-mt-md-40 u-mt-20">
            <Text modifiers={['fontUrmAvo', 'pigment', 'center', 'uppercase', '700', '20x24']}>
              HÂN HẠNH ĐƯỢC ĐÓN TIẾP QUÝ KHÁCH
            </Text>
          </div>
        </div>
      </div>
    </NumberBannerLayout>
  </div>
);

InvitationInfo.defaultProps = {
  value: undefined,
  label: undefined,
};

export default InvitationCard;
