/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import 'App.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter, Redirect, Route, RouteProps, Switch,
} from 'react-router-dom';

import SocketPush from 'helpers/SocketPush';
import AppInitializer from 'helpers/appinitializer';
import useInAppDetect from 'hooks/useInAppDetect';
import AwardAnnouncement from 'pages/AwardAnnouncement';
import CheckIn from 'pages/CheckIn';
import CheckInStart from 'pages/CheckInStart';
import ForgetPassword from 'pages/ForgetPassword';
import Foyer from 'pages/Foyer';
import Home from 'pages/Home';
import Instruction from 'pages/Instruction';
import Introduction from 'pages/Introduction';
import Invitation from 'pages/Invitation';
import Livestream from 'pages/Livestream';
import NumberCongratulation from 'pages/NumberCongratulation';
import NumberLogin from 'pages/NumberLogin';
import NumberWelcome from 'pages/NumberWelcome';
import Picking from 'pages/Picking';
import Prize from 'pages/Prize';
import PrizeSpecial from 'pages/PrizeSpecial';
import RegisterSecond from 'pages/RegisterSecond';
import Test from 'pages/Test';
import Test2 from 'pages/Test2';
import Waiting from 'pages/Waiting';
import { getTokenSession } from 'services/common/storage';
import { store } from 'store';
import { getGeneralAction } from 'store/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMeAction } from 'store/me';
import RedirectToPage from 'utils/RedirectToPage';
import SLUG_PARAMS from 'utils/constants';

const routes = [
  {
    pathname: SLUG_PARAMS.REGISTER,
    component: Home,
  },
  {
    pathname: SLUG_PARAMS.WAITING,
    component: Waiting,
    isAuth: true,
  },
  {
    pathname: SLUG_PARAMS.HOME,
    component: Introduction,
  },
  {
    pathname: SLUG_PARAMS.NUMBER_LOGIN,
    component: NumberLogin,
  },
  {
    pathname: SLUG_PARAMS.FORGET_PASSWORD,
    component: ForgetPassword,
  },
  {
    pathname: SLUG_PARAMS.NUMBER_WELCOME,
    component: NumberWelcome,
    isAuth: true,
  },
  {
    pathname: SLUG_PARAMS.NUMBER_INSTRUCTION,
    component: Instruction,
    isAuth: true,
  },
  {
    pathname: SLUG_PARAMS.FOYER,
    component: Foyer,
    isAuth: true,
  },
  {
    pathname: SLUG_PARAMS.NUMBER_PICKING,
    component: Picking,
    isAuth: true,
  },
  {
    pathname: SLUG_PARAMS.NUMBER_CONGRATULATION,
    component: NumberCongratulation,
    isAuth: true,
  },
  {
    pathname: SLUG_PARAMS.NUMBER_INVITATION,
    component: Invitation,
    isAuth: true,
  },
  {
    pathname: SLUG_PARAMS.REGISTER_V2,
    component: RegisterSecond,
  },
  {
    pathname: SLUG_PARAMS.LIVESTREAM,
    component: Livestream,
    isAuth: true,
  },
  {
    pathname: '/test',
    component: Test,
  },
  {
    pathname: '/test2',
    component: Test2,
  },
  {
    pathname: SLUG_PARAMS.CHECKIN_START,
    component: CheckInStart,
  },
  {
    pathname: SLUG_PARAMS.CHECKIN,
    component: CheckIn,
  },
  {
    pathname: SLUG_PARAMS.ENDING,
    component: AwardAnnouncement,
    isAuth: true,
  },
  {
    pathname: `${SLUG_PARAMS.PRIZE}/:slug`,
    component: Prize,
  },
  {
    pathname: SLUG_PARAMS.SPECIAL_PRIZE,
    component: PrizeSpecial,
  },
];

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const token = getTokenSession();
  const {
    // eslint-disable-next-line no-shadow
    component: Component, ...rest
  } = props;
  return (
    <Route
      {...rest}
      render={(routeProps) => (token ? (
        <Component {...routeProps} />
      ) : (
        <Redirect to={SLUG_PARAMS.NUMBER_LOGIN} />
      ))}
    />
  );
};

const App: React.FC = (props) => {
  const token = getTokenSession();
  const dispatch = useAppDispatch();
  const inApp = useInAppDetect();

  useEffect(() => {
    dispatch(getGeneralAction());
    if (token) {
      dispatch(getMeAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (inApp) {
    return inApp === 'ios' ? (
      <span>
        Vui lòng nhấn và giữ
        {' '}
        <a href={window.location.href}>liên kết này</a>
        {' '}
        trong 02 giây để chuyển đổi trình duyệt phù hợp và trải nghiệm
      </span>
    ) : (
      <span>
        Vui lòng nhấn vào
        {' '}
        <a href={`intent://${window.location.host}${window.location.pathname}${window.location.search}#Intent;scheme=${window.location.protocol.replace(/\W/g, '')};action=android.intent.action.VIEW;end;`}>liên kết này</a>
        {' '}
        để chuyển đổi trình duyệt phù hợp và trải nghiệm.
      </span>
    );
  }
  return (
    <div className="app">
      <main className="wrapper">
        {/* <Suspense fallback={<div />}> */}
        <Switch>
          <RedirectToPage>
            {routes.map((route) => (route.isAuth ? (
              <PrivateRoute
                {...props}
                path={route.pathname}
                exact
                key={route.pathname}
                component={route.component}
              />
            ) : (
              <Route
                key={route.pathname}
                path={route.pathname}
                component={route.component}
                exact
              />
            )))}
          </RedirectToPage>
        </Switch>
        {/* </Suspense> */}
      </main>
    </div>
  );
};

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <BrowserRouter>
      <AppInitializer />
      <SocketPush />
      <App />
    </BrowserRouter>
  </Provider>
);

export default AppWrapper;
