import React from 'react';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import Popup from 'components/organisms/Popup';

interface NotifyPopupProps {
  openPopup: boolean;
  hasRevert?: boolean;
  message: string;
  handleClose?: () => void;
  handleRevert?: () => void;
}

const NotifyPopup: React.FC<NotifyPopupProps> = ({
  hasRevert, openPopup, message, handleClose, handleRevert,
}) => (
  <Popup
    modifiers="scanning"
    isOpenModal={openPopup}
    title="XIN LỖI QUÝ KHÁCH"
  >
    <div className="t-checkIn_popup">
      <div className="t-checkIn_popup_message">
        <Text modifiers={['red', 'center', '700', 'uppercase', 'fontUrmAvo']}>
          {message}
        </Text>
      </div>
      <div className="o-popup_button">
        <Button
          variant={['h50']}
          handleClick={handleClose}
        >
          ĐÓNG
        </Button>
        {
          hasRevert
          && (
            <Button
              variant={['h50']}
              handleClick={handleRevert}
            >
              NHẬP LẠI
            </Button>
          )
        }
      </div>
    </div>
  </Popup>
);
NotifyPopup.defaultProps = {
  handleClose: undefined,
  handleRevert: undefined,
  hasRevert: undefined,
};

export default NotifyPopup;
