/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/media-has-caption */
import Hls from 'hls.js';
import React, {
  useCallback, useEffect, useImperativeHandle, useRef, useState,
} from 'react';

import icLoading from 'assets/images/loading.gif';
import Icon from 'components/atoms/Icon';
import useMutedAutoplay from 'hooks/useMutedAutoplay';

interface TfcPlayerProps {
  audioLang?: string;
  src: string;
  handleEnd?: () => void;
  handleTimeUpdate?: (time: number) => void;
}

export interface TFCPlayerRef {
  play(): void;
  pause(): void;
}

const TfcPlayer = React.forwardRef<TFCPlayerRef, TfcPlayerProps>(({
  src,
  audioLang = 'vi',
  handleEnd,
  handleTimeUpdate,
}, ref) => {
  const playerRef = useRef<HTMLVideoElement>(null);
  const hlsRef = useRef<Hls>();
  const isLiveEnded = useRef(false);
  const isLoaded = useRef(false);
  const syncDelta = useRef(0);
  const liveSyncDurationCount = useRef(3);

  const [isLive, setIsLive] = useState(false);
  const [isDelay, setIsDelay] = useState(false);
  const [isWating, setIsWating] = useState(false);
  const [muted, setMuted] = useState(false);

  useImperativeHandle(ref, () => ({
    play() {
      console.log('TFCPlayer: action play');
      playerRef.current?.play();
    },
    pause() {
      console.log('TFCPlayer: action pause');
      playerRef.current?.pause();
    },
  }), []);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return () => {
        /* empty */
      };
    }

    if (Hls.isSupported()) {
      const hls = new Hls({
        liveSyncDurationCount: liveSyncDurationCount.current,
      });
      hls.loadSource(src);
      hls.attachMedia(player);

      hlsRef.current = hls;
      (window as any).hls = hls;

      hls.once(Hls.Events.LEVEL_LOADED, (event, data) => {
        syncDelta.current = liveSyncDurationCount.current > 1
          ? 0.5 * data.details.targetduration
          : 0;
        setIsLive(data.details.live);
      });

      return () => hls.destroy();
    }

    if (player.canPlayType('application/vnd.apple.mpegurl')) {
      player.src = src;
      const handleMeta = () => {
        if (player.duration === Infinity) {
          setIsLive(true);
        }
        player.removeEventListener('loadedmetadata', handleMeta);
      };
      player.addEventListener('loadedmetadata', handleMeta);
    }

    return () => {
      /* empty */
    };

    // Don't allow changing source, easier logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return;
    }

    const onLoad = () => {
      console.log('TFCPlayer: onLoadData');
      isLoaded.current = true;

      player.removeEventListener('loadeddata', onLoad);
    };

    player.addEventListener('loadeddata', onLoad);
  }, []);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return () => {
        /* empty */
      };
    }

    const onPause = () => {
      if (!isLoaded.current) {
        console.log('TFCPlayer: not prevent pause before load');
      } else if (isLiveEnded.current) {
        // TODO remove, not effective
        console.log('TFCPlayer: not prevent pause after end');
      } else if (player.currentTime < player.duration) {
        console.log('TFCPlayer: prevent pause');
        player.play();
      } else {
        console.log('TFCPlayer: not prevent pause time >= duration');
      }
    };

    player.addEventListener('pause', onPause);

    return () => player.removeEventListener('pause', onPause);
  }, []);

  useMutedAutoplay(playerRef, setMuted);

  /**
   * change audioLang to default on start-up only
   */
  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return () => {
        /* empty */
      };
    }

    const hls = hlsRef.current;
    const { audioTracks } = player as any;

    if (hls) {
      hls.once(Hls.Events.MANIFEST_LOADED, () => {
        hls.audioTrack = Math.max(
          hls.audioTracks.findIndex((t) => t.lang === audioLang),
          0,
        );
      });
    } else if (audioTracks) {
      const onTrackAdd = () => {
        if (audioTracks.length > 1) {
          Array.from(audioTracks).forEach((t: any) => {
            // eslint-disable-next-line no-param-reassign
            t.enabled = t.language === audioLang;
          });
        }
      };

      audioTracks.addEventListener('addtrack', onTrackAdd);

      return () => audioTracks.removeEventListener('addtrack', onTrackAdd);
    }

    return () => {
      /* empty */
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return () => {
        /* empty */
      };
    }

    const onWaiting = () => setIsWating(true);
    const onResume = () => setIsWating(false);

    player.addEventListener('waiting', onWaiting);
    player.addEventListener('playing', onResume);
    player.addEventListener('canplay', onResume);

    return () => {
      player.removeEventListener('waiting', onWaiting);
      player.removeEventListener('playing', onResume);
      player.removeEventListener('canplay', onResume);
    };
  }, []);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return () => {
        /* empty */
      };
    }

    const hls = hlsRef.current;

    const handleSync = () => {
      if (hls) {
        const { liveSyncPosition } = hls;

        if (liveSyncPosition) {
          // TODO optimize throttle
          setIsDelay(player.currentTime < liveSyncPosition - 10);
        }
      } else if (player.duration === Infinity) {
        const end = player.seekable.end(player.seekable.length - 1);
        setIsDelay(player.currentTime < end - 10);
      }
    };

    player.addEventListener('progress', handleSync);
    player.addEventListener('timeupdate', handleSync);
    player.addEventListener('durationchange', handleSync);

    return () => {
      player.removeEventListener('progress', handleSync);
      player.removeEventListener('timeupdate', handleSync);
      player.removeEventListener('durationchange', handleSync);
    };
  }, []);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return () => {
        /* empty */
      };
    }

    const onDurationChange = () => {
      if (player.paused) {
        console.log('TFCPlayer: resume on network failed!');
        player.play();
        isLiveEnded.current = false;
      }
    };
    player.addEventListener('durationchange', onDurationChange);

    return () => {
      player.removeEventListener('durationchange', onDurationChange);
    };
  }, []);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return;
    }

    const hls = hlsRef.current;
    const { audioTracks } = player as any;

    if (hls) {
      hls.audioTrack = Math.max(
        hls.audioTracks.findIndex((t) => t.lang === audioLang),
        0,
      );
    } else if (audioTracks) {
      Array.from(audioTracks).forEach((t: any) => {
        // eslint-disable-next-line no-param-reassign
        t.enabled = t.language === audioLang;
      });
    }
  }, [audioLang]);

  const syncLive = useCallback(() => {
    const player = playerRef.current;

    if (!player) {
      return;
    }

    const hls = hlsRef.current;

    if (hls) {
      if (
        hls.levels[1]?.details?.live
        && hls.liveSyncPosition
        && player.currentTime < hls.liveSyncPosition
      ) {
        player.currentTime = hls.liveSyncPosition + syncDelta.current;
        if (player.paused) player.play();
      }
    } else if (player.duration === Infinity) {
      const end = player.seekable.end(player.seekable.length - 1);
      if (player.currentTime < end) {
        player.currentTime = end;
      }
    }
  }, []);

  const onEnded = useCallback(() => {
    console.log('TFCPlayer: onEnded');

    isLiveEnded.current = true;

    handleEnd?.();
  }, [handleEnd]);

  return (
    <div className="o-tfcplayer">
      <video
        ref={playerRef}
        style={{ maxWidth: '100%' }}
        muted={muted}
        autoPlay
        playsInline
        disablePictureInPicture
        disableRemotePlayback
        // eslint-disable-next-line react/no-unknown-property
        x-webkit-airplay="deny"
        onEnded={onEnded}
        onTimeUpdate={(event) => handleTimeUpdate
          && handleTimeUpdate(event.currentTarget.currentTime)}
      />
      {isLive && (
        <div
          className="o-tfcplayer_liveIcon"
          onClick={() => {
            if (isDelay) {
              syncLive();
            }
          }}
        >
          <span className={isDelay ? 'delay' : 'islive'}>LIVE</span>
        </div>
      )}
      <div
        className="t-livestream_sound"
        onClick={() => setMuted(!muted)}
      >
        <Icon iconName={muted ? 'mute' : 'sound'} iconSize="24x24" />
      </div>
      {/* <div className="t-livestream_code">
        <Text modifiers={['10x14', '400', 'uppercase', 'center', 'white']}>
          COR-C-70-23
        </Text>
      </div> */}

      {isWating && (
        <div className="o-tfcplayer_loading">
          <img src={icLoading} alt="loading" />
        </div>
      )}
    </div>
  );
});

export default TfcPlayer;
