/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import InstructionTemplate from 'components/templates/Instruction';
import { useAppSelector } from 'store/hooks';
import SLUG_PARAMS from 'utils/constants';
import mapModifiers from 'utils/functions';

const Instruction: React.FC = () => {
  const history = useHistory();
  const { data: dataProfile } = useAppSelector((state) => state.me);

  useEffect(() => {
    if (!dataProfile) return;
    if (dataProfile.number_lucky === 0) history.push(SLUG_PARAMS.NUMBER_INVITATION);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProfile]);

  return (
    <div className={mapModifiers('p-instruction')}>
      <InstructionTemplate />
    </div>
  );
};

export default Instruction;
