import {
  ConfirmSelectedNumber, PrizesTypes, SelectedNumberParamsTypes, SelectedNumberResponseTypes,
} from './type';

import axiosInstance from 'services/common/instance';

const getNumberSelectedService = async (): Promise<SelectedNumberResponseTypes> => {
  const res = await axiosInstance.post('members/start-number-selection');
  return res.data.data;
};

export const postSelectedNumberService = async (params: SelectedNumberParamsTypes) => {
  const res = await axiosInstance.post('members/select-number', params);
  return res.data;
};

export const postConfirmNumberSelection = async (params?: ConfirmSelectedNumber) => {
  const res = await axiosInstance.post('members/confirm-number-selection', params);
  return res.data;
};

export const getPrizesService = async (all?: number): Promise<PrizesTypes[]> => {
  const res = await axiosInstance.get('prizes', { params: { all } });
  return res.data.data;
};

export default getNumberSelectedService;
