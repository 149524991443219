/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Form from 'components/organisms/Form';
import Popup from 'components/organisms/Popup';

export type CheckInPhoneTypes = {
  phone: string;
}

interface PhonePopupProps {
  openPopup: boolean;
  loading?: boolean;
  method: UseFormReturn<CheckInPhoneTypes>;
  handleSubmit: (data: CheckInPhoneTypes) =>void;
  handleClose?: ()=> void;
}

const PhonePopup: React.FC<PhonePopupProps> = ({
  openPopup, method, handleSubmit, handleClose,
  loading,
}) => (
  <Popup
    modifiers="scanning"
    isOpenModal={openPopup}
    title="MỜI QUÝ KHÁCH NHẬP SỐ ĐIỆN THOẠI"
  >
    <div className="t-checkIn_popup">
      <Form method={method} submitForm={handleSubmit}>
        <Controller
          name="phone"
          render={({ field, fieldState }) => (
            <Input
              label="Số điện thoại"
              id="phone"
              value={field.value}
              error={fieldState?.error?.message}
              handleChange={field.onChange}
            />
          )}
        />
        <br />
        <div className="o-popup_button">
          <Button
            variant={['h50']}
            handleClick={handleClose}
          >
            ĐÓNG
          </Button>
          <Button
            loading={loading}
            type="submit"
            variant={['h50']}
          >
            BẮT ĐẦU
          </Button>
        </div>
      </Form>
    </div>

  </Popup>
);
PhonePopup.defaultProps = {
  loading: undefined,
  handleClose: undefined,
};
export default PhonePopup;
