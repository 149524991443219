/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import bannerMobileSrc from 'assets/images/banner-mobile.jpg';
import bannerSrc from 'assets/images/customer.jpg';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import HeadingSection from 'components/molecules/HeadingSection';
import Banner from 'components/organisms/Banner';
import Container from 'components/organisms/Container';
import Footer from 'components/organisms/Footer';
import Player from 'components/organisms/Player';
import PrizeCollapse from 'components/organisms/PrizeCollapse';
import DeviceWrappedVertical from 'components/templates/DeviceWrappedVertical';
import eventConfigs from 'configs';
import useMutedAutoplay from 'hooks/useMutedAutoplay';
import { getPrizesService } from 'services/picking';
import { PrizesTypes } from 'services/picking/type';
import { useAppSelector } from 'store/hooks';
import { numberWithCommas, scrollDownNextSection } from 'utils/functions';

const AwardAnnouncementContainer:React.FC = () => {
  const infoRef = useRef<HTMLDivElement>(null);
  const awardRef = useRef<HTMLDivElement>(null);
  // const videoRef = useRef<HTMLVideoElement>(null);
  const { data: meData } = useAppSelector((state) => state.me);

  const [muted, setMuted] = useState(true);

  // useMutedAutoplay(videoRef, setMuted);

  // onScrollPlayer
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > window.innerHeight / 2 && videoRef.current) {
  //       videoRef.current.play();
  //       window.removeEventListener('scroll', handleScroll);
  //     }
  //   };
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const [prizes, setPrizes] = useState<PrizesTypes[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getPrizesService();
        setPrizes(res);
      } catch (error) {
        // error
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listAward = useMemo(() => prizes.map((val) => {
    const awardPrize = val.award ? numberWithCommas(parseInt(val.award, 10)) : 0;
    return ({
      id: val.id,
      title: `${val.number_prize} Giải ${val.name}`,
      isSpecial: val.number_prize === 1,
      data: val.details.filter((it) => !it.member_previously_won).map((item) => ({
        id: item.member_id,
        name: item.member.shop_name,
        address: item.member.address || '-',
        number: item.number_choosed,
      })),
    });
  }), [prizes]);

  return (
    <DeviceWrappedVertical requiredVertical>
      <div className="p-awardAnnouncement">
        <section>
          <Banner
            imageDesktop={bannerSrc}
            imageMobile={bannerMobileSrc}
            imageTablet={bannerSrc}
            hasInner={false}
            outSide={(
              <div style={{ display: 'none' }} className="o-banner_outSide">
                <div className="o-banner_outSide_btnLeft">
                  <Button
                    color="pigment"
                    variant="secondary"
                    className="active"
                    size="sm"
                    handleClick={() => scrollDownNextSection(infoRef)}
                  >
                    chương trình hội nghị
                  </Button>
                </div>
                <div className="o-banner_outSide_btnRight">
                  <Button
                    color="pigment"
                    variant="secondary"
                    className="active"
                    size="sm"
                    handleClick={() => scrollDownNextSection(awardRef)}
                  >
                    danh sách trúng thưởng
                  </Button>
                </div>
              </div>
          )}
          />
        </section>
        {/* <section style={{ display: 'none' }}
        className="p-awardAnnouncement_info" id="showInfo" ref={infoRef}>
          <div className="t-showInfo">
            <Container>
              <div className="t-showInfo_inner">
                <div className="t-showInfo_title">
                  <HeadingSection>CHƯƠNG TRÌNH HỘI NGHỊ</HeadingSection>
                </div>
                <div className="t-showInfo_wrapper">
                  <video
                    muted={muted}
                    src={meData?.event_time.recap || eventConfigs.finishVideo}
                    ref={videoRef}
                    loop
                    controls
                  />
                  <div className="t-showInfo_mute" onClick={() => setMuted(!muted)}>
                    <Icon iconName={muted ? 'mute' : 'sound'} iconSize="32x32" />
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </section> */}
        <section ref={awardRef} className="p-awardAnnouncement_listAward">
          <Container>
            <div className="p-awardAnnouncement_listAward_heading">
              <HeadingSection>
                danh sách trúng thưởng
              </HeadingSection>
            </div>
            <div className="p-awardAnnouncement_listAward_list">
              {listAward.map((item, index) => (
                <div key={`item-${index.toString()}`} className="p-awardAnnouncement_listAward_item">
                  <PrizeCollapse
                    defaultCollapse
                    modifiers={item.isSpecial ? ['active', 'announce'] : ['announce']}
                    title={item.title}
                    prizeData={item.data}
                  />
                </div>
              ))}
            </div>
          </Container>
        </section>
        <Footer />
      </div>
    </DeviceWrappedVertical>
  );
};

export default AwardAnnouncementContainer;
