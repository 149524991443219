/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// import logo from 'assets/images/double-logo.png';
import ballImg from 'assets/images/prizes/ball_light.png';
import specialText from 'assets/images/prizes/title-chucmung.png';
import Heading from 'components/atoms/Heading';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import Container from 'components/organisms/Container';
import mapModifiers, { numberWithCommas } from 'utils/functions';

export type PrizeNotifyTypes = {
  number: number;
  shopName: string;
  address: string;
  area: string;
  phone: string;
  hasDestroy: boolean;
}

interface PrizeNotifyProps {
  data?: PrizeNotifyTypes;
  prizeName?: string;
  isSpecial?: boolean;
  award?: number;
}

const PrizeNotify: React.FC<PrizeNotifyProps> = ({
  data, prizeName, isSpecial, award,
}) => (
  <div className={mapModifiers('t-prizeNotify', isSpecial && 'special')}>
    {/* <div className="t-prizeNotify_background">
        <img src={!isSpecial ? backgroundSpecial : bgQuiz} alt="background" />
      </div> */}
    {/* <div className="t-prizeNotify_header">
        <img src={logo} alt="logo" />
      </div> */}
    <div className="t-prizeNotify_content">
      <Container fullScreen>
        {data?.hasDestroy ? (
          <div className="t-prizeNotify_exist">
            <div className="t-prizeNotify_exist_content">
              <div className="t-prizeNotify_number">
                <Image size="cover" imgSrc={ballImg} ratio="1x1" alt="number" />
                <div className="number">{data?.number}</div>
              </div>
              <div className="t-prizeNotify_exist_text">
                <Text modifiers={['28x36', 'uppercase', '700', 'white', 'center']}>
                  SỐ MAY MẮN THUỘC VỀ KHÁCH HÀNG
                  {' '}
                  {data?.shopName}
                  <br />
                  LÀ KHÁCH HÀNG ĐÃ TRÚNG GIẢI.
                </Text>
                <Text modifiers={['28x36', 'uppercase', '700', 'white', 'center']}>
                  BAN TỔ CHỨC XIN PHÉP HỦY SỐ TRÚNG THƯỞNG NÀY VÀ TIẾP TỤC
                  <br />
                  QUAY SỐ TÌM NGƯỜI TRÚNG THƯỞNG KHÁC.
                </Text>
              </div>
            </div>
          </div>
        ) : (
          <div className="t-prizeNotify_content_wrap">
            <div className={`t-prizeNotify_heading${isSpecial ? ' special' : ''}`}>
              <Heading type="h1" modifiers={['700', 'uppercase', 'white', 'center']}>
                CHÚC MỪNG QUÝ KHÁCH
              </Heading>
            </div>
            {
                isSpecial && (
                <div className="t-prizeNotify_price">
                  <Heading type="h3" modifiers={['700', 'uppercase', 'goldLinear2', 'center']}>
                    { award ? numberWithCommas(award) : 0}
                    {' '}
                    VNĐ
                  </Heading>
                </div>
                )
              }
            <div className="t-prizeNotify_content_flex">
              {
                data && (
                  <>
                    <div className="t-prizeNotify_title">
                      <Heading type="h3" modifiers={['700', 'uppercase', 'white', 'center']}>
                        đã trúng giải thưởng
                        <br />
                        chuyến tham quan và xem đội bóng Real Madrid
                        <br />
                        thi đấu tại tây ban nha 2024
                      </Heading>
                    </div>
                    <div className="t-prizeNotify_number">
                      <Image size="cover" imgSrc={ballImg} ratio="1x1" alt="number" />
                      <div className="number">{data.number}</div>
                    </div>
                    <div className="t-prizeNotify_info">
                      <div className="t-prizeNotify_info_name">
                        Cửa hàng
                        {' '}
                        <p>{data.shopName}</p>
                      </div>
                      <div className="t-prizeNotify_info_address">
                        <Text modifiers={[isSpecial ? '700' : '400', 'uppercase',
                          isSpecial ? 'normal' : 'center', 'white', isSpecial ? '32x38' : '20x24']}
                        >
                          địa chỉ:
                          {' '}
                          {data.address}
                        </Text>
                      </div>
                    </div>
                  </>
                )
              }
            </div>
          </div>
        )}

      </Container>
    </div>
  </div>
);

PrizeNotify.defaultProps = {
  data: undefined,
  isSpecial: undefined,
  prizeName: undefined,
  award: undefined,
};

export default PrizeNotify;
