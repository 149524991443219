import React from 'react';

import CheckInStartContainer from 'containers/CheckIn/start';

const CheckInStart: React.FC = () => (
  <div className="p-checkInStart">
    <CheckInStartContainer />
  </div>
);

export default CheckInStart;
