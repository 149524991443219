import React, { useMemo } from 'react';

import NumberCongrate from 'components/templates/NumberCongrate';
import { useAppSelector } from 'store/hooks';

const NumberCongratulation: React.FC = () => {
  // const history = useHistory();
  // const dispatch = useAppDispatch();
  const profileData = useAppSelector((state) => state.me.data);
  // const eventTimeCode = useAppSelector((state) => state.me.data?.event_time_code);

  const numberSelectedList = useMemo(() => {
    if (!profileData) return [];
    return profileData.lucky_draws.map((val) => val.number_choosed.toString());
  }, [profileData]);
  /**
   * SOCKET
   */
  // const socketLivestream = useCallback(() => {
  //   dispatch(getMeAction()).unwrap().then(() => {
  //     history.push(SLUG_PARAMS.LIVESTREAM);
  //   });
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // useEffect(() => {
  //   if (!eventTimeCode) {
  //     return;
  //   }
  //   const channel = echo.private(`EventTime.${eventTimeCode}`);
  //   channel.listen('updated', socketLivestream);
  //   // eslint-disable-next-line consistent-return
  //   return () => {
  //     channel.stopListening('updated', socketLivestream);
  //   };
  // }, [eventTimeCode, socketLivestream]);

  return (
    <div className="p-numberCongratulation">
      <NumberCongrate
        account={profileData?.account}
        time={profileData?.event_time.show_time_start}
        address={profileData?.event_time.address}
        numberPickedList={numberSelectedList}
        numberAvailable={profileData?.number_lucky}
      />
    </div>
  );
};

export default NumberCongratulation;
