import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import CheckInStart, { CheckInFormTypes } from 'components/templates/CheckInStart';
import { getCheckInStatus } from 'store/checkIn';
import { useAppDispatch } from 'store/hooks';
import SLUG_PARAMS from 'utils/constants';
import { checkInSchema } from 'utils/schemas';

const CheckInStartContainer: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const method = useForm<CheckInFormTypes>({
    resolver: yupResolver(checkInSchema),
    mode: 'onChange',
    defaultValues: {
      place: undefined,
      startDate: undefined,
    },
  });

  const handleCheckIn = (data: CheckInFormTypes) => {
    dispatch(getCheckInStatus(data));
    history.push(SLUG_PARAMS.CHECKIN);
  };

  return (
    <CheckInStart
      method={method}
      handleSubmit={handleCheckIn}
    />
  );
};

export default CheckInStartContainer;
