/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
// import { debounce } from 'lodash-es';
import useResumeFg from 'hooks/useResumeFg';
import { debounce } from 'lodash-es';
import { useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getTokenSession } from 'services/common/storage';

// import useResumeFg from 'hooks/useResumeFg';

// import { setInteractives, setQuestions } from 'store/questions';
// import { getSystemAction, setEndedLivestream, setSocketStatus } from 'store/system';

import getTokenWebSocketService from 'services/websocket';
import socket from 'services/websocket/socket';
import {
  addNewEmojiSocket, addNewLuckyDrawsSocket, addNewNumberSocket,
  addResetNumberSocket, setSocketStatus,
} from 'store/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMeAction } from 'store/me';
import SLUG_PARAMS from 'utils/constants';

const SocketPush: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.me.data);
  const init = useRef(true);
  const throttledFetchSystem = useMemo(
    () => debounce(
      () => {
        if (location.pathname !== '/livestream') {
          console.log('Get System when resume from background');
          if (getTokenSession()) {
            dispatch(getMeAction());
          }
        }
      },
      3000,
    ),
    [dispatch, location.pathname],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (getTokenSession()) {
        dispatch(getMeAction()).unwrap().then((res) => {
          if (res.redirect_to_page.includes('LIVESTREAM')) {
            clearInterval(interval);
            if (location.pathname === SLUG_PARAMS.NUMBER_CONGRATULATION) {
              history.push(SLUG_PARAMS.LIVESTREAM);
            }
          }
        });
      }
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useResumeFg(throttledFetchSystem);

  useEffect(() => {
    if (user && init.current) {
      init.current = false;
      const { host, protocol } = window.location;
      const wsHost = `${protocol === 'https:' ? 'wss' : 'ws'}://${host}/ws`;
      // dev
      // const wsHost = 'wss://staging.hoinghikhachhangclp.com/ws';
      socket(
        wsHost,
        getTokenWebSocketService,
        {
          onMessage: (data: string) => {
            const parsedData = JSON.parse(data);
            switch (parsedData.event) {
              case 'updated':
                dispatch(getMeAction());
                break;
              case 'LuckyNumberResult':
                dispatch(addNewLuckyDrawsSocket(parsedData.data));
                break;
              case 'emoji':
                dispatch(addNewEmojiSocket(parsedData.data));
                break;
              case 'NumberSelection':
                dispatch(addNewNumberSocket({ number: parsedData.data, userData: user }));
                break;
              case 'ResetNumberSelection':
                dispatch(addResetNumberSocket(parsedData.data));
                break;
              // case 'openWaiting1':
              // case 'openFoyer':
              // case 'openThankYou':
              //   dispatch(getSystemAction(parsedData.event));
              //   break;
              // case 'openLivestream':
              //   setTimeout(() => {
              //     dispatch(getSystemAction(parsedData.event));
              //   }, 5000 + 3000 * Math.random());
              //   break;
              // case 'notifyQuestionAllUser':
              //   dispatch(setInteractives({
              //     timeBroadcasted: parsedData.data.timeBroadcasted,
              //     data: parsedData.data.interactiveActivityQuestion,
              //   }));
              //   break;
              // case 'Approved':
              //   dispatch(setQuestions({
              //     ...parsedData.data,
              //     approvedAt: parsedData.data.approved_at,
              //   }));
              //   break;
              // case 'EndedLivestream':
              //   dispatch(setEndedLivestream(true));
              //   break;
              default:
                break;
            }
          },
          onStateChange: (status) => {
            dispatch(setSocketStatus(status));
          },
        },
      );
    }
  }, [dispatch, user]);

  return null;
};

export default SocketPush;
