/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import jsQR from 'jsqr';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Webcam from 'react-webcam';

import InfoPopup from './infoPopup';
import NotifyPopup from './notifyPopup';
import PhonePopup, { CheckInPhoneTypes } from './phonePopup';
import QrPopup from './qrPopup';
import SuccessPopup from './successPopup';

import CheckIn from 'components/templates/CheckIn';
import { getCheckInByPhone, requestCheckCodeService, requestCheckInService } from 'services/checkin';
import { CheckInUserInfoTypes } from 'services/checkin/type';
import { useAppSelector } from 'store/hooks';
import SLUG_PARAMS from 'utils/constants';
import { formatDateEvent } from 'utils/functions';
import { phoneSchema } from 'utils/schemas';

const CheckInContainer: React.FC = () => {
  const { data: checkInData } = useAppSelector((state) => state.checkin);
  const [checkInPhoneLoading, setCheckinPhoneLoading] = useState(false);
  const webcamRef = useRef<Webcam>(null);
  const { data: meData } = useAppSelector((state) => state.me);
  const [isShowScanQr, setShowScanQr] = useState(false);
  const [infoUserData, setUserInfoData] = useState<CheckInUserInfoTypes>();
  const { eventData } = useAppSelector((state) => state.checkin);
  const history = useHistory();

  const [errMessage, setErrMessage] = useState('');
  const [openPopup, setOpenPopup] = useState({
    phoneScan: false,
    invalidError: false,
    invalidPhone: false,
    info: false,
    success: false,
    qr: false,
  });

  useEffect(() => {
    if (!checkInData) {
      history.push(SLUG_PARAMS.CHECKIN_START);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInData]);
  /**
   * FORM METHOD
   */
  const phoneScanMethod = useForm<CheckInPhoneTypes>({
    resolver: yupResolver(phoneSchema),
    mode: 'onChange',
  });
  useEffect(() => {
    if (openPopup.phoneScan) phoneScanMethod.reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPopup.phoneScan]);

  /**
   * CheckIn With Phone Number
   */
  const handlePhoneSubmit = (data: CheckInPhoneTypes) => {
    if (!checkInData) return;
    setCheckinPhoneLoading(true);
    (async () => {
      try {
        const res = await getCheckInByPhone({
          event_time_code: checkInData?.startDate.value || '',
          phone: data.phone,
          dau_cau: checkInData?.place.value || '',
        });
        setUserInfoData(res);
        setOpenPopup((prev) => ({
          ...prev,
          phoneScan: false,
          info: true,
        }));
      } catch (error) {
        setOpenPopup((prev) => ({
          ...prev,
          phoneScan: false,
          invalidPhone: true,
        }));
        setErrMessage('SỐ ĐIỆN THOẠI KHÔNG HỢP LỆ');
      } finally {
        setCheckinPhoneLoading(false);
      }
    })();
  };

  /**
   * CheckIn With QR Code
   */
  const handleScanQr = (code: string) => {
    if (!checkInData) return;
    (async () => {
      try {
        const res = await requestCheckCodeService({
          event_time_code: checkInData.startDate.value || '',
          shop_code: code,
          dau_cau: checkInData.place.value || '',
        });
        setUserInfoData(res);
        setOpenPopup((prev) => ({
          ...prev,
          qr: false,
          info: true,
        }));
      } catch (error) {
        setOpenPopup((prev) => ({
          ...prev,
          qr: false,
          invalidError: true,
        }));
        setErrMessage('THÔNG TIN QUÝ KHÁCH KHÔNG TRÙNG KHỚP VỚI SỰ KIỆN NÀY !');
      }
    })();
  };

  /**
   * SCAN QR Code
   */
  const tick = () => {
    if (webcamRef && webcamRef.current && !isShowScanQr) {
      const canvas = webcamRef.current.getCanvas();
      if (canvas) {
        const ctx = canvas.getContext('2d');
        if (ctx) {
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          if (imageData) {
            const code = jsQR(imageData.data, imageData.width, imageData.height, {
              inversionAttempts: 'dontInvert',
            });

            if (code) {
              handleScanQr(code.data.replace('http://', ''));
              setShowScanQr(false);
            }
          }
        }
      }
    }
    requestAnimationFrame(tick);
  };
  useEffect(() => {
    requestAnimationFrame(tick);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Request CheckIn with UserInfo
   */

  const requestCheckIn = async () => {
    if (!checkInData || !infoUserData) return;
    try {
      await requestCheckInService({
        event_time_code: checkInData.startDate.value || '',
        dau_cau: checkInData.place.value || '',
        shop_code: infoUserData?.code || '',
      });
      setOpenPopup((prev) => ({
        ...prev,
        invalidError: false,
        phoneScan: false,
        qr: false,
        info: false,
        success: true,
      }));
    } catch (error) {
      setOpenPopup((prev) => ({
        ...prev,
        qr: false,
        invalidError: true,
        info: false,
      }));
      setErrMessage('THÔNG TIN QUÝ KHÁCH KHÔNG TRÙNG KHỚP VỚI SỰ KIỆN NÀY !');
    }
  };

  return (
    <>
      <CheckIn
        handleScanQr={() => {
          setOpenPopup((prev) => ({
            ...prev,
            qr: true,
          }));
          setShowScanQr(true);
        }}
        handleCheckPhoneNumber={() => setOpenPopup((prev) => ({
          ...prev,
          phoneScan: true,
        }))}
      />

      {/* POPUP  */}
      <PhonePopup
        loading={checkInPhoneLoading}
        method={phoneScanMethod}
        openPopup={openPopup.phoneScan}
        handleSubmit={handlePhoneSubmit}
        handleClose={() => setOpenPopup((prev) => ({ ...prev, phoneScan: false }))}
      />
      <NotifyPopup
        message={errMessage}
        openPopup={openPopup.invalidError}
        handleClose={() => setOpenPopup((prev) => ({
          ...prev,
          invalidError: false,
        }))}
      />

      {/* Invalid Phone number  */}
      <NotifyPopup
        message={errMessage}
        openPopup={openPopup.invalidPhone}
        handleClose={() => setOpenPopup((prev) => ({
          ...prev,
          invalidPhone: false,
        }))}
        hasRevert
        handleRevert={() => setOpenPopup((prev) => ({
          ...prev,
          invalidPhone: false,
          phoneScan: true,
        }))}
      />
      <SuccessPopup
        openPopup={openPopup.success}
        handleClose={() => setOpenPopup((prev) => ({
          ...prev,
          success: false,
        }))}
      />
      <QrPopup
        scanQr={isShowScanQr}
        ref={webcamRef}
        openPopup={openPopup.qr}
        handleClose={() => setOpenPopup((prev) => ({
          ...prev,
          qr: false,
        }))}
      />
      <InfoPopup
        userInfo={{
          name: infoUserData?.name || '',
          shopCode: infoUserData?.code || '',
          phoneNumber: infoUserData?.phone || '',
          date: infoUserData?.event_time.show_time_start || '',
          area: infoUserData?.dau_cau || '',
        }}
        openPopup={openPopup.info}
        handleClose={() => setOpenPopup((prev) => ({
          ...prev,
          info: false,
        }))}
        handleCheckIn={requestCheckIn}
      />
    </>
  );
};

export default CheckInContainer;
