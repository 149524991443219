/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';

import backgroundMobileImg from 'assets/images/bg-forgetpassword-mobile.jpg';
import backgroundImg from 'assets/images/bg-welcome.jpg';
import logo from 'assets/images/double-logo.png';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import mapModifiers from 'utils/functions';

interface NumberBannerLayoutProps {
  background?: string;
  alignRight?: boolean;
  alignCenter?: boolean;
}

const NumberBannerLayout: React.FC<NumberBannerLayoutProps> = (
  {
    children, background, alignRight, alignCenter,
  },
) => (
  <div className={mapModifiers('o-numberBannerLayout',
    alignRight && 'alignRight',
    alignCenter && 'alignCenter')}
  >
    <div className="o-numberBannerLayout_logo">
      <img src={logo} alt="logo" />
    </div>
    {/* <div className="o-numberBannerLayout_code">
      <Text modifiers={['10x14', '400', 'uppercase', 'center', 'white']}>
        COR-C-70-23
      </Text>
    </div> */}
    {/* BACKGROUND  */}
    {/* <div className="o-numberBannerLayout_background">
      <Image
        imgSrc={background || backgroundImg}
        srcMobile={background || backgroundMobileImg}
        srcTablet={background || backgroundMobileImg}
        size="cover"
        alt="background"
        ratio="1368x768"
      />
    </div> */}
    <div className="o-numberBannerLayout_content">
      {children}
    </div>
  </div>
);

NumberBannerLayout.defaultProps = {
  background: undefined,
  alignRight: undefined,
  alignCenter: undefined,
};

export default NumberBannerLayout;
