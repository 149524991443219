import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import backgroundMobile from 'assets/images/banner-mobile-login.jpg';
import logo from 'assets/images/logo-login.png';
import logoText from 'assets/images/logo-text-login.png';
import background from 'assets/images/numberChoosing/numberLogin.jpg';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Image from 'components/atoms/Image';
import Input from 'components/atoms/Input';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import Form from 'components/organisms/Form';
import SLUG_PARAMS from 'utils/constants';

export type NumberLoginFormTypes = {
  username: string;
  password: string;
}

interface NumberLoginTemplateProps {
  method: UseFormReturn<NumberLoginFormTypes>;
  forgetLink: string;
  loading?: boolean;
  handleSubmit: (data: NumberLoginFormTypes) => void;
}

const NumberLoginTemplate: React.FC<NumberLoginTemplateProps> = ({
  method, forgetLink, loading = false, handleSubmit,
}) => {
  const history = useHistory();
  return (
    <div className="t-numberLogin">
      {/* BACKGROUND  */}
      <div className="t-numberLogin_background">
        <Image
          imgSrc={background}
          srcMobile={backgroundMobile}
          srcTablet={backgroundMobile}
          size="cover"
          alt="background"
          ratio="1368x768"
        />
      </div>
      <div className="t-numberLogin_background_mobile">
        <div className="t-numberLogin_background_logo">
          <Image
            imgSrc={logo}
            alt="logo"
            size="contain"
            ratio="1230x638"
          />
        </div>
        <div className="t-numberLogin_background_text">
          <Image
            imgSrc={logoText}
            alt="logo"
            size="contain"
            ratio="927x846"
          />
        </div>
      </div>
      {/* FORM  */}
      <div className="t-numberLogin_form">
        <div className="t-numberLogin_form_title">
          <Heading type="h3" modifiers={['md', 'center', '700', 'pigment', 'uppercase']}>
            Đăng nhập
          </Heading>
        </div>
        <div className="t-numberLogin_form_desc">
          <Text modifiers={['taupeGray', 'sm', 'center']}>
            Tên truy cập và mật khẩu đã được gửi qua tin nhắn xác nhận đăng ký của quý khách hàng
          </Text>
        </div>
        <Form method={method} submitForm={handleSubmit}>
          <div className="t-numberLogin_form_wrap">
            <div className="t-numberLogin_form_input">
              <Controller
                name="username"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Input
                    value={value}
                    id="username"
                    modifiers={['secondary']}
                    placeholder="Tên truy cập"
                    label="Tên truy cập"
                    error={error?.message}
                    handleChange={onChange}
                    autoComplete="off"
                  />
                )}
              />
            </div>
            <div className="t-numberLogin_form_input">
              <Controller
                name="password"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Input
                    value={value}
                    id="password"
                    type="password"
                    modifiers={['secondary']}
                    placeholder="Mật khẩu"
                    label="Mật khẩu"
                    error={error?.message}
                    handleChange={onChange}
                    autoComplete="off"
                  />
                )}
              />
            </div>
            <div className="t-numberLogin_form_button">
              <Button
                loading={loading}
                variant={['h50']}
                type="submit"
              >
                ĐĂNG NHẬP
              </Button>
            </div>
            <div className="t-numberLogin_form_label">
              <Text modifiers={['richElectricBlue3', 'center', 'uppercase', '500', '16x22']}>
                <Link href={forgetLink}>
                  QUÊN THÔNG TIN ĐĂNG NHẬP?
                </Link>
              </Text>
            </div>
            <div className="t-numberLogin_form_button">
              <Button
                variant={['h50']}
                type="submit"
                handleClick={() => history.push(SLUG_PARAMS.REGISTER_V2)}
              >
                ĐĂNG KÝ
              </Button>
            </div>
          </div>
        </Form>
      </div>
      {/* <div className="t-numberLogin_code">
        <Text modifiers={['10x14', '400', 'uppercase', 'center', 'white']}>
          COR-C-70-23
        </Text>
      </div> */}
    </div>
  );
};

NumberLoginTemplate.defaultProps = {
  loading: undefined,
};

export default NumberLoginTemplate;
