import React from 'react';

import Button from 'components/atoms/Button';
import Popup from 'components/organisms/Popup';

export type UserCheckInInfoTypes = {
  name: string;
  shopCode: string;
  phoneNumber: string;
  date: string;
  area: string;
}

interface InfoPopupProps {
  openPopup: boolean;
  userInfo: UserCheckInInfoTypes;
  handleClose?: () => void;
  handleCheckIn?: () => void;
}

const InfoPopup: React.FC<InfoPopupProps> = ({
  openPopup, userInfo, handleClose, handleCheckIn,
}) => (
  <Popup
    modifiers="scanning"
    isOpenModal={openPopup}
    title="CHÀO MỪNG QUÝ KHÁCH"
  >
    <div className="t-checkIn_popup">
      <div className="t-checkIn_popup_name">
        {userInfo.name}
      </div>
      <div className="t-checkIn_popup_info">
        <ul>
          <li>
            Mã cửa hàng:
            {' '}
            <b>{userInfo.shopCode}</b>
          </li>
          <li>
            Số điện thoại:
            {' '}
            <b>{userInfo.phoneNumber}</b>
          </li>
          <li>
            Ngày event:
            {' '}
            <b>{userInfo.date}</b>
          </li>
          <li>
            Đầu cầu:
            {' '}
            <b>{userInfo.area}</b>
          </li>
        </ul>
      </div>
      <div className="o-popup_button">
        <Button
          variant={['h50']}
          handleClick={handleClose}
        >
          ĐÓNG
        </Button>
        <Button
          variant={['h50']}
          handleClick={handleCheckIn}
        >
          CHECK IN
        </Button>
      </div>
    </div>
  </Popup>
);
InfoPopup.defaultProps = {
  handleClose: undefined,
  handleCheckIn: undefined,
};

export default InfoPopup;
