import React from 'react';

import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import Popup from 'components/organisms/Popup';

interface PolicyPopupProps {
  openPopup: boolean;
  handleClose?: () => void;
}

const PolicyPopup: React.FC<PolicyPopupProps> = ({
  openPopup,
  handleClose,
}) => (
  <div className="t-policyPopup">
    <Popup
      hasCloseButton
      isOpenModal={openPopup}
      title="ĐIỀU KHOẢN LƯU TRỮ THÔNG TIN"
      handleClose={handleClose}
    >
      <Text modifiers={['sm', 'royalAzure', '700']}>Chi tiết điều khoản:</Text>
      <br />

      <div className="t-policyPopup_list">
        <ul>
          <li>
            <Text modifiers={['sm', 'black', '400']}>
              Tôi đồng ý cho Công ty TNHH Dinh Dưỡng 3A Việt Nam lưu trữ và sử
              dụng các thông tin tôi đã cung cấp ở trên.
            </Text>
          </li>
          <li>
            <Text modifiers={['sm', 'black', '400']}>
              Tôi đồng ý cho Công ty TNHH Dinh Dưỡng 3A Việt Nam liên hệ tôi qua
              thư điện tử/ điện thoại/ tin nhắn về các nội dung: thông tin về
              hội nghị các điểm tin chính sau hội nghị của công ty.
            </Text>
          </li>
          <li>
            <Text modifiers={['sm', 'black', '400']}>
              Tôi hiểu rằng thông tin đăng ký của tôi sẽ được chia sẻ cho các
              đơn vị ủy quyền của Công ty TNHH Dinh Dưỡng 3A Việt Nam nhằm mục
              đích trên.
            </Text>
          </li>
          <li>
            <Text modifiers={['sm', 'black', '400']}>
              Tôi hiểu rằng để ngừng nhận thông tin thì tôi phải liên hệ với
              nhân viên Công ty TNHH Dinh Dưỡng 3A Việt Nam thông qua số Tổng
              đài:
              {' '}
              <Link href="tel:19001519">19001519</Link>
              .
            </Text>
          </li>
          <li>
            <Text modifiers={['sm', 'black', '400']}>
              Tôi hiểu rằng để đảm bảo an toàn tài khoản đăng nhập vào chương trình, tôi tuyệt đối
              {' '}
              <Text type="span" modifiers={['sm', 'black', '700']}>
                KHÔNG CUNG CẤP THÔNG TIN
              </Text>
              {' '}
              đăng nhập, mật khẩu, mã OTP,... cho bất cứ ai. Nếu xảy ra sự cố tranh chấp
              về tài khoản đăng nhập tôi hoàn toàn chịu mọi trách nhiệm.
            </Text>
          </li>
          <li>
            <Text modifiers={['sm', 'black', '400']}>
              Tôi hiểu rằng Công ty TNHH Dinh Dưỡng 3A Việt Nam có quyền dùng
              thông tin trúng thưởng của tôi để báo cáo các cơ quan nhà nước
              hoặc để quảng cáo mà không phải trả thêm khoản chi phí nào.
            </Text>
          </li>
        </ul>
      </div>
      <br />

      <div className="o-popup_title">
        <span>
          ĐIỀU KHOẢN CHƯƠNG TRÌNH
        </span>
      </div>
      <br />
      <Text modifiers={['sm', 'black', '400']}>
        Chi tiết về chương trình Quý khách vui lòng xem tại:
        {' '}
        <a href="/tnc.pdf" className="t-policyPopup_link" target="_blank">ĐIỀU KHOẢN CHƯƠNG TRÌNH</a>
      </Text>
    </Popup>
  </div>
);

PolicyPopup.defaultProps = {
  handleClose: undefined,
};

export default PolicyPopup;
