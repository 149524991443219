import React from 'react';

import Button from 'components/atoms/Button';
import Popup from 'components/organisms/Popup';

interface SuccessPopupProps {
  openPopup: boolean;
  handleClose?: () => void;
}

const SuccessPopup: React.FC<SuccessPopupProps> = ({
  openPopup, handleClose,
}) => (
  <Popup
    modifiers="scanning"
    isOpenModal={openPopup}
    title="CHÀO MỪNG QUÝ KHÁCH ĐẾN VỚI HỘI NGHỊ"
  >
    <div className="t-checkIn_popup">
      <div className="o-popup_button">
        <Button
          variant={['h50']}
          handleClick={handleClose}
        >
          ĐÓNG
        </Button>
      </div>
    </div>
  </Popup>
);
SuccessPopup.defaultProps = {
  handleClose: undefined,
};

export default SuccessPopup;
