/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';

import PrizeNotify from 'components/templates/PrizeNotify';
import { getPrizesService } from 'services/picking';
import { PrizesTypes } from 'services/picking/type';

const SpecialPrizeContainer: React.FC = () => {
  const [prizeData, setPrizeData] = useState<PrizesTypes>();

  useEffect(() => {
    (async () => {
      const data = await getPrizesService();
      setPrizeData(data[0]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prizePopupInfo = useMemo(() => {
    if (prizeData && prizeData.details && prizeData.details.length > 0) {
      return {
        shopName: prizeData.details[prizeData.details.length - 1].member.name,
        number: prizeData.details[prizeData.details.length - 1].number_choosed,
        address: prizeData.details[prizeData.details.length - 1].member.address,
        area: prizeData.details[prizeData.details.length - 1].member.dau_cau,
        phone: prizeData.details[prizeData.details.length - 1].member.phone,
        hasDestroy: prizeData.details[prizeData.details.length - 1].member_previously_won,
      };
    }
    return undefined;
  }, [prizeData]);
  return (
    <PrizeNotify
      prizeName="giải đặc biệt"
      isSpecial
      award={Number(prizeData?.award)}
      data={prizePopupInfo}
    />
  );
};

export default SpecialPrizeContainer;
