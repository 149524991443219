import React from 'react';

import Image from 'components/atoms/Image';

export interface EventsProps {
  imgSrc: string;
  imgSrc2: string;
}

const Events: React.FC<EventsProps> = ({
  imgSrc,
  imgSrc2,
}) => (
  <div className="o-events">
    <div className="o-events_image">
      <Image size="cover" alt="img" imgSrc={imgSrc} ratio="1x1" />
    </div>
    <div className="o-events_image">
      <Image size="cover" alt="img" imgSrc={imgSrc2} ratio="1x1" />
    </div>
  </div>
);

export default Events;
