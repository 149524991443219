/* eslint-disable no-unused-vars */
import React from 'react';

import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import Popup from 'components/organisms/Popup';

interface SuccessPopupProps {
  // account?: {
  //   name: string;
  //   password: string;
  // };
  eventTime?: string;
  eventTime2?: string;
  openPopup: boolean;
  handleClose?: () => void;
}

const SuccessPopup: React.FC<SuccessPopupProps> = ({
  openPopup,
  // account,
  eventTime,
  eventTime2,
  handleClose,
}) => (
  <div className="t-policyPopup">
    <Popup
      modifiers="success"
      isOpenModal={openPopup}
      title="ĐĂNG KÝ THÀNH CÔNG"
      hasCloseButton
      handleClose={handleClose}
    >
      <Text modifiers={['black', '400', 'center', '14x19']}>
        Quý khách đã đăng ký thành công.
      </Text>
      <Text modifiers={['black', '400', 'center', '14x19']}>
        Chương trình Quay số trúng thưởng chuyến tham quan và
        xem đội bóng Real Madrid thi đấu tại Tây Ban Nha 2024 sẽ diễn ra vào lúc
      </Text>
      <Text modifiers={['black', '400', 'center', '14x19']}>
        13 giờ 00 ngày 12/09/2024 tại trang
        {' '}
        <Link useExternal href="https://hoinghikhachhangclp.com" target="_blank">
          <Text type="span" modifiers={['royalAzure', '700', 'center', '14x19', 'underline']}>
            https://hoinghikhachhangclp.com
          </Text>
        </Link>
      </Text>
      {/* <br />
      <Text modifiers={['royalAzure', '400', 'center', '14x19']}>
        vui lòng
        {' '}
        <strong>KHÔNG</strong>
        {' '}
        cung cấp thông tin đăng nhập bên dưới cho bất kỳ ai.
      </Text> */}
      <br />
      {/* <Text modifiers={['royalAzure', '400', 'center', '14x19']}>
        Tên tài khoản:
        {' '}
        <strong>
          {account?.name}
        </strong>
      </Text>
      <Text modifiers={['royalAzure', '400', 'center', '14x19']}>
        Mật mã:
        {' '}
        <strong>
          {account?.password}
        </strong>
      </Text> */}
      <br />
      <div className="o-popup_button">
        <Button
          handleClick={handleClose}
        >
          HOÀN TẤT
        </Button>
      </div>
    </Popup>
  </div>
);

SuccessPopup.defaultProps = {
  handleClose: undefined,
};

export default SuccessPopup;
