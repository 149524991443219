/* eslint-disable no-unused-vars */
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import circleImg from 'assets/images/numberChoosing/circle-shape.svg';
import logo from 'assets/images/numberChoosing/logo-double.png';
import backgroundMobile from 'assets/images/numberChoosing/numberLogin-mobile.png';
import background from 'assets/images/numberChoosing/numberLogin.jpg';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Pulldown, { OptionType } from 'components/molecules/Pulldown';
import Form from 'components/organisms/Form';
import EventCheckinProvider from 'providers/pulldown';

export type CheckInFormTypes = {
  startDate: OptionType;
  place: OptionType;
}

interface CheckInStartProps {
  method: UseFormReturn<CheckInFormTypes>;
  loading?: boolean;
  handleSubmit: (data: CheckInFormTypes) => void;
}

const CheckInStart: React.FC<CheckInStartProps> = ({
  method, loading, handleSubmit,
}) => (
  <div className="t-checkInStart">
    {/* LOGO  */}
    <div className="t-checkInStart_logo">
      <img src={logo} alt="logo" />
    </div>
    {/* BACKGROUND  */}
    <div className="t-checkInStart_background">
      <Image
        imgSrc={background}
        srcMobile={backgroundMobile}
        srcTablet={backgroundMobile}
        size="cover"
        alt="background"
        ratio="1368x768"
      />
    </div>
    {/* FORM  */}
    <div className="t-checkInStart_form">
      <div className="t-checkInStart_form_texture">
        <img src={circleImg} alt="circle" />
      </div>
      <div className="t-checkInStart_form_title">
        <Button
          color="pigment"
          variant="secondary"
          size="sm"
          className="active"
        >
          <span>HỘI NGHỊ GẮN BÓ</span>
          <span>KHÁCH HÀNG</span>
          <span>CLP 2022</span>
        </Button>
      </div>
      <Form method={method} submitForm={handleSubmit}>
        <EventCheckinProvider>
          {({
            startDateOptions, placeOptions,
          }) => (
            <div className="t-checkInStart_form_wrap">
              <div className="t-checkInStart_form_input">
                <Controller
                  name="startDate"
                  render={({ field, fieldState }) => (
                    <Pulldown
                      {...field}
                      error={fieldState.error}
                      placeholder=""
                      label="Ngày sự kiện"
                      options={startDateOptions}
                    />
                  )}
                />
              </div>
              <div className="t-checkInStart_form_input">
                <Controller
                  name="place"
                  render={({ field, fieldState }) => (
                    <Pulldown
                      {...field}
                      label="Tên đầu cầu"
                      error={fieldState.error}
                      placeholder=""
                      options={placeOptions}
                    />
                  )}
                />
              </div>
              <div className="t-checkInStart_form_button">
                <Button
                  loading={loading}
                  variant={['h50']}
                  type="submit"
                >
                  BẮT ĐÂU
                </Button>
              </div>
            </div>
          )}
        </EventCheckinProvider>

      </Form>
    </div>
  </div>
);

CheckInStart.defaultProps = {
  loading: undefined,
};

export default CheckInStart;
