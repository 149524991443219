import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import NumberWelcomeTemplate from 'components/templates/NumberWelcome';
import { useAppSelector } from 'store';
import SLUG_PARAMS from 'utils/constants';

const NumberWelcome: React.FC = () => {
  const history = useHistory();
  const { data: dataProfile } = useAppSelector((state) => state.me);

  useEffect(() => {
    if (!dataProfile) return;
    if (!dataProfile.event_time.number_selection_opened) {
      history.push(SLUG_PARAMS.NUMBER_INVITATION);
    }
  }, [dataProfile, history]);
  useEffect(() => {
    document.body.classList.add('body-introduction');
  }, []);
  return (
    <div className="p-numberWelcome">
      <NumberWelcomeTemplate urlIntro={SLUG_PARAMS.NUMBER_INSTRUCTION} />
    </div>
  );
};

export default NumberWelcome;
