import { GuideItemTypes } from 'components/templates/Guide';

const contentsTopData: GuideItemTypes = {
  title: 'HƯỚNG DẪN CHỌN SỐ DỰ THƯỞNG',
  informations: [
    'Trước buổi quay số, hệ thống sẽ được mở từ ngày <b>08/09/2024</b> đến ngày <b>09/09/2024</b> để quý khách chọn số.',
    'Quý khách có 02 ngày để chọn số từ khi hệ thống được mở.',
    'Quý khách truy cập trang web: <a href="https://hoinghikhachhangclp.com" target="_blank"><b> https://hoinghikhachhangclp.com </b></a> hoặc quét mã QR in trên thiệp mời để vào trang chọn số.',
    'Nhập <b> Tên truy cập </b> và <b> Mật khẩu </b> đã được gửi trong tin nhắn xác nhận để đăng nhập.',
    'Chọn số dự thưởng theo hướng dẫn từ trang web.',
    'Mỗi tài khoản phải chọn tất cả số dự thưởng trong một lần duy nhất.',
    'Kết thúc thời gian chọn số, hệ thống sẽ gửi tin nhắn xác nhận số đã chọn đến quý khách.',
    'Nếu có vấn đề phát sinh hoặc thắc mắc, quý khách vui lòng liên hệ Tổng đài <br/> <a href="tel:0918454049"><b> 0918 45 40 49 </b></a> để được hỗ trợ.',
  ],
};

export const contentsBottomData: GuideItemTypes = {
  title: 'HƯỚNG DẪN THAM GIA BUỔI QUAY SỐ',
  informations: [
    'Quý khách truy cập trang web: <a href="https://hoinghikhachhangclp.com" target="_blank"><b> https://hoinghikhachhangclp.com </b></a> hoặc quét mã QR in trên thiệp mời để vào tham gia buổi quay số từ 13 giờ 00 đến 16 giờ 00 ngày 12/09/2024.',
    'Nhập <b> Tên truy cập </b> và <b> Mật khẩu </b> đã được gửi trong tin nhắn xác nhận để đăng nhập và theo dõi buổi quay số.',
    'Nếu có vấn đề phát sinh hoặc thắc mắc, quý khách vui lòng liên hệ Tổng đài <br/> <a href="tel:0918454049"><b> 0918 45 40 49 </b></a>để được hỗ trợ.',
  ],
};

export default contentsTopData;
