/* eslint-disable react/require-default-props */
import React, {
  forwardRef,
} from 'react';
import Webcam from 'react-webcam';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import Popup from 'components/organisms/Popup';

interface QrPopupProps {
  openPopup: boolean;
  scanQr?: boolean;
  handleClose?: () => void;
}

const QrPopup: React.ForwardRefRenderFunction<Webcam, QrPopupProps> = ({
  openPopup, scanQr, handleClose,
}, ref) => (
  <Popup
    modifiers="scanning"
    isOpenModal={openPopup}
  >
    <div className="t-checkIn_popup">
      <div className="t-checkIn_popup_video">
        <div className="wrap">
          {
            scanQr
            && (
              <Webcam
                ref={ref}
                id="webcam"
                audio={false}
                videoConstraints={{
                  width: 224,
                  height: 224,
                  facingMode: { exact: 'environment' },
                }}
              />
            )
          }
        </div>
      </div>
      <Text modifiers={['pigment', '400', 'fontUrmAvo', 'center', '16x22']}>
        Đặt mã QR ở trung tâm hình vuông để camera có thể quét
      </Text>
      <div className="o-popup_button">
        <Button
          variant={['h50']}
          handleClick={handleClose}
        >
          ĐÓNG
        </Button>
      </div>
    </div>
  </Popup>
);

export default forwardRef(QrPopup);
